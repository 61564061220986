/************ banner css start *************/

.rt_banner_slider_main_item {
    background: #FFFFFF;
    border-radius: 20px;
    overflow: hidden;
}

/* section.rt_banner_section {
        margin-top: 20px;
    } */
    .rt_banner_section {
        padding-top: 0;
    }
.rt_banner_slider_main_text_content {
    width: 55%;
    margin: auto;
}

.slick-slider.rt_banner_slider_slick.slick-initialized {
    box-shadow: 0px 0px 44px #c4e4ff;
    background: #e1f1ff;
    overflow: hidden;
    border-radius: 20px;
}

.slick-slider.rt_banner_slider_slick.slick-initialized ul.slick-dots {
    bottom: 30px;
    left: 13% !important;
    width: 65px;
}

.rt_banner_slider_main_img img {
    width: 100%;
}

.slick-slider.rt_banner_slider_slick.slick-initialized ul.slick-dots li button:before {
    content: "";
    font-size: 35px;
    width: 23px;
    height: 4px;
    background: #000000;
    border-radius: 30px;
}

button.rt_banner_btn {
    background: linear-gradient(89deg, #EAB558 0.46%, rgba(234, 181, 88, 0) 71.01%);
    opacity: 0.6;
    border-radius: 24.8458px 0px 0px 0px;
    transform: rotate(-180deg);
    border: none;
    padding: 8px 36px;
}

button.rt_banner_btn a {
    font-weight: 700;
    font-size: 18px;
    text-transform: uppercase;
    color: #000000;
    transform: rotate(-180deg);
    display: flex;
}

.rt_banner_slider_main_text_content p {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #131951;
    margin: 10px 0px 18px 0px;
}

.rt_banner_slider_main_text_content h1 {
    font-size: 42px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #131951;
    font-weight: 500;
}

/************ banner css end *************/
/*Categories CSS*/
.rt_categories_section {
    padding: 5rem 0;
}

.rt_heading h2 {
    font-size: 35px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 30px;
}

.rt_categories_slider .slick-initialized .slick-slide {
    padding: 7px;
}

.rt_categories_content {
    text-align: center;
    padding: 12px 12px 0;
    background: #FFFFFF;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
}

.rt_categories_content img {
    border-radius: 20px;
    width: 100%;
    margin: 0 auto;
    display: block;
    height: 170px;
    object-fit: cover;
}

.rt_categories_content h3 {
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    margin: 15px 0;
    color: #000;
}

.rt_categories_slider .slick-slider button.slick-arrow.slick-next,
.rt_categories_slider .slick-slider button.slick-arrow.slick-prev {
    bottom: -100px !important;
}

.rt_categories_slider .slick-prev {
    content: "";
    background: url('../Images/left.png') !important;
    background-size: 100% !important;
    width: 40px;
    height: 40px;
    z-index: 9;
    background-repeat: no-repeat !important;
    position: absolute;
    right: 0;
    left: -60px;
    top: inherit !important;
    margin: auto !important;
    background-position: 50%;
}

.rt_categories_slider .slick-next {
    content: "";
    background: url('../Images/right.png') !important;
    background-size: 100% !important;
    width: 40px;
    height: 40px;
    z-index: 9;
    background-repeat: no-repeat !important;
    position: absolute;
    right: -50px;
    left: 0;
    margin: auto;
    top: inherit !important;
    background-position: 50%;
}

.rt_categories_slider .slick-prev:before,
.rt_categories_slider .slick-next:before {
    display: none;
}

.rt_categories_slider .slick-next.slick-disabled,
.rt_categories_slider .slick-prev.slick-disabled {
    opacity: .25;
}

/*Categories CSS*/
/*Rugs Size CSS*/
.rt_rugsize_section {
    padding: 5rem 0;
}

.rt_heading1 h2 {
    font-size: 35px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 10px;
}

.rt_heading1 p {
    text-align: center;
    color: rgb(0 0 0 / 52%);
    font-weight: 500;
    font-size: 19px;
    line-height: 24px;
}

.rt_rugsize_content {
    background-color: #fff;
    border-radius: 50%;
    height: 285px;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 6px solid #fff;
    cursor: pointer;
    margin: 20px auto 10px;
}

.rt_rugsize_content:hover,
.rt_rugsize_content:active {
    border-color: rgba(5, 106, 175, 0.11);
}

.rt_rugsize_content img {
    width: 200px;
    max-width: 100%;
    margin: 0 auto;
    display: block;
    padding: 10px;
}

/*Rugs Size CSS*/
/*Area Rugs CSS*/
.rt_area_section {
    padding: 5rem 0;
}

.rt_heading2 h2 {
    font-size: 35px;
    text-align: left;
    font-weight: 500;
    margin-bottom: 10px;
}

.rt_heading2 p {
    text-align: left;
    color: rgb(0 0 0 / 52%);
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
}

.rt_area_content {
    position: relative;
}

.rt_area_content img {
    width: 100%;
    margin: 0 auto;
}

p.rt_off span {
    width: 100px;
    display: block;
    padding: 2px 5px;
    font-weight: 800;
    font-size: 13px;
}

p.rt_off {
    margin: 0;
    position: absolute;
    top: 5px;
    left: 5px;
    background-color: #FFAE35;
    color: #fff;
    width: 50px;
}

.rt_area_content h3 {
    font-size: 18px;
    text-align: center;
    margin: 10px 0 5px;
    color: #000;
    font-weight: 500;
}

.rt_area_content h5 {
    font-size: 16px;
    text-align: center;
    margin: 0;
    color: #000;
    font-weight: 500;
}

.rt_area_content_main .slick-initialized .slick-slide {
    padding: 7px;
}

.rt_area_content_main .slick-slider button.slick-arrow.slick-next,
.rt_area_content_main .slick-slider button.slick-arrow.slick-prev {
    top: -60px !important;
}

.rt_area_content_main .slick-prev {
    content: "";
    background: url('../Images/left.png') !important;
    background-size: 100% !important;
    width: 40px;
    height: 40px;
    z-index: 9;
    background-repeat: no-repeat !important;
    position: absolute;
    right: 0;
    left: 83%;
    top: inherit !important;
    margin: auto !important;
    background-position: 50%;
}

.rt_area_content_main .slick-next {
    content: "";
    background: url('../Images/right.png') !important;
    background-size: 100% !important;
    width: 40px;
    height: 40px;
    z-index: 9;
    background-repeat: no-repeat !important;
    position: absolute;
    right: -93%;
    left: 0;
    margin: auto;
    top: inherit !important;
    background-position: 50%;
}

.rt_area_content_main .slick-prev:before,
.rt_area_content_main .slick-next:before {
    display: none;
}

.rt_area_content_main .slick-next.slick-disabled,
.rt_area_content_main .slick-prev.slick-disabled {
    opacity: .25;
}

.rt_area_content_right {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.rt_area_content_right img {
    width: 95%;
    margin: 0 auto;
    display: block;
}

/*Area Rugs CSS*/
/*Newsletter CSS*/
.rt_newsletter_section {
    padding: 2rem 0 0;
}

.rt_newsletter_content {
    background-color: #fff;
    border-radius: 20px;
    text-align: center;
    padding: 50px;
}

.rt_newsletter_content h2 {
    font-size: 30px;
    font-weight: 400;
    text-transform: uppercase;
}

.rt_newsletter_content p {
    font-weight: 500;
    font-size: 17px;
}

.rt_news_form {
    width: 40%;
    margin: 30px auto;
    margin-bottom: 10px;
}

.rt_newsletter_content input,
.rt_newsletter_content input:focus {
    background-color: #EDEDED;
    border: none;
    outline: none;
    box-shadow: none;
    border-radius: 30px;
    padding: 17px 20px;
    font-weight: 500;
    font-size: 18px;
    font-family: 'Nunito', sans-serif !important;
}

.rt_newsletter_content button,
.rt_newsletter_content button:active,
.rt_newsletter_content button:focus,
.rt_newsletter_content button:hover {
    background-color: transparent;
    outline: none;
    box-shadow: none;
    border: none;
    position: absolute;
    top: 0;
    right: 0;
}

.rt_news_form img {
    width: 60px;
    position: absolute;
    right: 0px;
    top: 1px;
}

.btn-check:active+.btn-primary:focus,
.btn-check:checked+.btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none !important;
}

/*Newsletter CSS*/

/*Responsive Footer Css*/

@media (min-width:1024px) and (max-width:1199px) {
    .rt_rugsize_content {
        height: 230px;
        width: 230px;
    }
    .rt_news_form {
        margin: 20px auto;
        margin-bottom: 0px;
    }
    .rt_area_content_main .slick-prev {
        left: 75%;
    }
    .rt_categories_section {
        padding: 3rem 0;
    }
    .rt_area_section {
        padding: 2rem 0;
    }
    .rt_area_section {
        padding: 2rem 0;
    }
    .rt_footer_content.rt_footer_content_left img {
        width: 100%;
    }
    .rt_footer_content_left img {
        margin-bottom: 10px;
    }
    .rt_footer_content_left p {
        font-size: 14px;
        padding-right: 15px;
    }
    .rt_rugsize_section {
        padding-bottom: 2rem;
    }
}
@media (min-width: 320px) and (max-width: 359px){
    .rt_rugsize_content {
        width: 130px;
        height: 130px;
        margin: 20px auto 0;
    }
}
@media (min-width: 360px) and (max-width: 575px){
    .rt_rugsize_content {
        width: 150px;
        height: 150px;
        margin: 20px auto 0;
    }
}
@media (min-width: 320px) and (max-width: 767px){  
    .rt_banner_slider_main_text_content {
        width: 85%;
        margin: auto;
        height: 160px;
        padding: 15px 0px;
        text-align: center;
        margin-bottom: 25px;
    }
    .rt_news_form img {
        width: 56px;
    }
    .rt_heading h2 {
        font-size: 24px;
        margin-bottom: 20px;
    }
    .rt_heading1 h2 {
        font-size: 24px;
        margin-bottom: 10px;
    }  
    .rt_categories_slider .slick-slider button.slick-arrow.slick-next, .rt_categories_slider .slick-slider button.slick-arrow.slick-prev {
        bottom: -90px !important;
    }
    .rt_banner_slider_main_item {
        border-radius: 0;
        margin-top: 5px;
    }
    .rt_rugsize_section {
        padding: 5rem 0 0;
    }
    .rt_heading1 p {
        font-size: 16px;
        line-height: 20px;
    }
    .rt_newsletter_content {
        padding: 30px 10px 10px;
    }
    .rt_newsletter_content h2 {
        font-size: 20px;
    }    
    .rt_newsletter_content p {
        font-size: 14px;
    }
    .rt_news_form {
        width: 100%;
    }
    .rt_news_form img {
        top: -1px;
    } 
    .rt_area_content_right {
        display: none;
    }
    .rt_area_section {
        padding: 4rem 0 4rem;
    }
    .rt_area_content_main .slick-slider button.slick-arrow.slick-next, .rt_area_content_main .slick-slider button.slick-arrow.slick-prev {
        bottom: -65px !important;
        top: unset !important;
    } 
    .rt_area_content_main .slick-next {
        right: -70px;
    } 
    .rt_area_content_main .slick-prev {
        left: -40px;
    }
    .rt_heading2 h2 {
        font-size: 24px;
        text-align: center;
    }
    .rt_newsletter_section {
        padding: 1.5rem 0 0;
    }
   
    .rt_newsletter_content input, .rt_newsletter_content input:focus {
        font-size: 14px;
    }
    .rt_heading2 p {
        text-align: center;
        font-size: 16px;
    }
}
@media (min-width:768px) and (max-width:991px) {
    .rt_banner_slider_main_text_content {
        width: 77%;
        margin: auto;
    }
    .rt_categories_content img {
        height: 100px;
    }

    .rt_banner_slider_main_text_content h1 {
        font-size: 32px !important;
    }
    .rt_banner_slider_main_text_content p {
        font-size: 15px;
        line-height: 22px;
        margin: 10px 0px 18px 0px;
    }
    .slick-slider.rt_banner_slider_slick.slick-initialized ul.slick-dots {
        bottom: 8px;
        left: 6% !important;
        width: 65px;
    }
    .rt_area_content_right {
        display: none;
    }
    .rt_area_content_main .slick-prev {
        left: 78%;
    }
    .rt_area_content h3 {
        font-family: 'Nunito', sans-serif !important;
    }
    .rt_news_form {
        width: 60%;
    }  
    .rt_area_section {
        padding: 2rem 0;
    }
    .rt_rugsize_section {
        padding: 3rem 0;
    }
}
@media (min-width:992px) and (max-width:1149px) {
    .rt_rugsize_content {
        height: 220px;
        width: 220px;
    } 
    .rt_area_content_main .slick-prev {
        left: 77%;
    } 
    .rt_rugsize_content img {
        width: 160px;
    }
    .rt_area_section {
        padding: 2rem 0;
    }
}
@media (min-width:1150px) and (max-width:1440px) {
    .rt_rugsize_content {
        height: 250px;
        width: 250px;
    }   
    .rt_area_content_main .slick-prev {
        left: 80%;
    } 
    .rt_area_section,.rt_rugsize_section {
        padding: 3rem 0;
    }
    .rt_categories_content img {
        height: 130px;
        object-fit: cover;
    }
}
/*Responsive Footer Css*/
