/* add to cart overlay button  style start*/
.rt_image_overlay_middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    padding: 25px 25px;
    background-color: #fffffff2;
}
.rt_product_image_hover{
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}
.rt_nunito-font {
  font-family: "Nunito", sans-serif !important;
}
.rt_product_image_hover:hover .rt_image_overlay_middle{
   opacity: 1;
}
.rt_product_list_content_top:hover .rt_image_overlay_middle{
opacity: 1;
}
.rt_productlist_add_to_cart_button {
    padding: 10px 30px;
    border: 1px solid #000;
    color: #000;
    font-size: 16px;
    font-weight: 500 !important;
    background-color: #fff;
    margin-top: 20px;
    border-radius: 4px;
    width: 100%;
}
.rt_productlist_add_to_cart_button:hover, .rt_productlist_add_to_cart_button:active, .rt_productlist_add_to_cart_button:focus{
outline: none;
box-shadow: 0;
}
.rt_Qyantity {
    color: #000;
    font-size: 14px;
    line-height: 15px;
    font-weight: 500 !important;
}
/* add to cart overlay button  style end*/

.rt_price_pera_div {
    display: flex;
    justify-content: space-between;
}
.rt_product_list_heading h5 {
    font-size: 16px;
}
/*Product Banner Css*/
.rt_productlist_section_bg {
  background-image: url("../Images/productList/bg_list.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 4rem 0;
  text-align: center;
}

.rt_productlist_filter ul li:nth-last-child(1) select {
  border-right: 1px solid #ced4da;
}

.rt_productlist_filter ul li select {
  border-right: none;
}

.rt_productlist_banner_content {
  background-color: #fff;
  border-radius: 10px;
  width: 20%;
  margin: 0 auto;
  display: block;
  padding: 20px 0;
}

.rt_productlist_banner_content h1 {
  font-size: 30px;
  font-weight: 500;
  text-transform: uppercase;
}

.rt_productlist_banner_content ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rt_productlist_banner_content li {
  padding: 0 2px;
}

.rt_productlist_banner_content li a {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
}

/*Product Banner Css*/
/*Product Filter Css*/
.rt_filter_tage_main {
  margin-top: 15px;
}
.MuiAvatar-colorDefault.rt_login_user_name {
  background: #b4c7d7;
  cursor: pointer;
}

.rt_login_user_name {
  background: #b4c7d7;
  cursor: pointer;
}
.rt_productlist,
.rt_product_wishlist {
  padding: 4rem 0;
}

.rt_productlist {
  padding: 4rem 0 2rem 0;
}

.rt_productlist_filter ul li:last-child select,
.rt_productlist_filter ul li:last-child select:active,
.rt_productlist_filter ul li:last-child select:focus,
.rt_productlist_filter ul li:last-child select:hover {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.rt_productlist_filter ul li:first-child select,
.rt_productlist_filter ul li:first-child select:active,
.rt_productlist_filter ul li:first-child select:hover,
.rt_productlist_filter ul li:first-child select:focus {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.rt_productlist_filter ul li select,
.rt_productlist_filter ul li select:active,
.rt_productlist_filter ul li select:hover,
.rt_productlist_filter ul li select:focus {
  border-radius: 0;
  padding: 0.8rem 2.25rem 0.8rem 0.75rem;
  outline: none;
  box-shadow: none;
  border-color: #ced4da;
}

.rt_productlist_filter ul {
  display: flex;
  align-items: center;
}

.rt_productlist_result p {
  margin: 0;
  color: #4f5665;
  font-weight: 600;
  font-size: 16px;
  text-align: left;
  margin-bottom: 10px;
}

.rt_productlist_shortby ul {
  display: flex;
  align-items: center;
  justify-content: end;
}

.rt_productlist_shortby ul li {
  background-color: #fff;
  padding: 9px;
}

.rt_productlist_shortby ul li:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.rt_productlist_shortby ul li:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.rt_productlist_shortby ul li h5 {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  padding: 5.5px 5px 5px 5px;
}

.rt_productlist_shortby ul li select,
.rt_productlist_shortby ul li select:active,
.rt_productlist_shortby ul li select:focus {
  border: none;
  padding: 5px 40px 3px 15px;
  box-shadow: none;
  outline: none;
}

/*Product Filter Css*/
/*Product List Css*/

.rt_dropfilter_main a {
  padding: 5px;
  border: none;
}
/* 

.rt_dropfilter_main .dropdown-toggle::after {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    height: max-content;
} */

.rt_dropfilter_item_main a {
  padding: 0 !important;
}
.rt_dropfilter_item_main {
  border: none;
  padding: 0;
}

.rt_productlist_filter.price_brand ul {
  display: flex;
  align-items: center;
  border-radius: 2px;
  width: max-content;
  border-right: 0;
  position: relative;
}
.rt_dropfilter_main button {
  text-align: left;
  border-right: 1px solid #e8e8e8 !important;
  padding: 10px 60px 10px 15px !important;
  text-align: left;
  border-right: 1px solid #e8e8e8 !important;
  background: none !important;
  color: black !important;
  border-color: gainsboro !important;
  background: white !important;
  box-shadow: none !important;
  outline: none !important;
}

.rt_dropfilter_main .dropdown-toggle::after {
  position: absolute;
  right: 10px;
  top: 8px;
}

.rt_dropfilter_item_main .list-group .list-group-item {
  border: none;
  border-bottom: 1px solid #e1e1e1;
  border-radius: 0;
}
.rt_productlist_filter ul li button {
  border-radius: 0;
  padding: 0.8rem 2.25rem 0.8rem 0.75rem;
  outline: none;
  box-shadow: none;
  border-color: #ced4da;
  background: white;
  color: black;
  border-right: 0;
}
.rt_drop_filet_main button.rt_drop_btn {
  background: white;
  border: inherit;
  color: black;
  width: 100%;
  border: 1px solid #dadada;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 110px;
  padding: 10px 5px;
  border-radius: 4px 0px 0px 4px;
}

.rt_drop_content_main {
  position: absolute;
  width: 50%;
  z-index: 9;
  margin-top: 2px;
}
.rt_drop_content_main {
  display: none;
}

.rt_drop_content_main.open {
  display: block;
}
.rt_drop_filet_main button.rt_drop_btn {
  background: white;
  border: inherit;
  color: black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 190px;
  padding: 10px 15px;
  border-right: 1px solid #dadada;
  border-radius: -1px 0px 5px 5px;
  box-shadow: none;
  outline: none;
}

/* .rt_acc_main h2 button {
    color: black !important;
} */

.rt_acc_main h2 .accordion-button:not(.collapsed)::after {
  transform: rotate(-180deg);
}
.rt_drop_content_main
  .list-group
  .list-group-item
  .form-check
  label.form-check-label {
  position: relative;
  top: 1px;
}
.rt_drop_content_main .list-group {
  border-radius: 0;
}

/*% off css*/
.tag-container {
  position: absolute;
  top: 30px;
  left: 0;
}

.tag {
  min-width: 75px;
  height: 30px;
  background-color: #0d4268;
  position: relative;
  padding: 0 0 0 10px;
  display: inline-block;
  margin-left: 4px;
}

.tag-title {
  line-height: 15px;
  color: #f0f0f0;
  -webkit-text-shadow: 0 1px 2px rgba(0, 0, 0, 0.75);
  -moz-text-shadow: 0 1px 2px rgba(0, 0, 0, 0.75);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.75);
  font-size: 14px;
  font-weight: 500;
  margin: 7px 0 0;
}

.tag-tail {
  position: absolute;
  top: 0;
  right: -15px;
}

[class^="tag-tail"]:after,
[class^="tag-tail"].tag-tail:before {
  content: "";
  width: 0;
  height: 0;
  display: block;
}

.tag-tail:after {
  border-right: 15px solid transparent;
  border-bottom: 15px solid #0d4268;
}

.tag-tail:before {
  border-right: 15px solid transparent;
  border-top: 15px solid #0d4268;
}

.tag.tag-drop:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-bottom: 9px solid #00233c;
  display: block;
  position: absolute;
  top: -9px;
  left: 0;
}

.tag.tag-drop:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-top: 9px solid #00233c;
  display: block;
  position: absolute;
  bottom: -9px;
  left: 0;
}

/*% off css*/
.rt_product_list_content {
    background-color: #fff;
    border-radius: 10px;
    padding: 10px 20px;
    position: relative;
    margin-bottom: 20px;
}

.rt_product_list_content img {
  width: 100%;
  height: 280px;
  object-fit: contain;
}

.rt_wishlist_icon span svg {
  width: 30px;
  height: 23px;
  line-height: 25px;
  margin-top: 4px;
  color: #000;
}

.rt_wishlist_icon_fill span svg {
  color: #F3759E;
}

.rt_wishlist_icon {
  position: absolute;
  top: 30px;
  right: 30px;
  background-color: #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

ul.rt_rating {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 100px;
  position: absolute;
  bottom: 10px;
  left: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

ul.rt_rating li h3 {
  font-size: 16px;
  font-family: "Nunito" !important;
  display: flex;
  font-weight: 600;
  margin: 0;
}

ul.rt_rating li h5 {
  font-size: 16px;
  font-weight: 400;
  font-family: "Nunito" !important;
  margin: 0;
}

ul.rt_rating li {
  padding: 5px;
}

ul.rt_rating li h3 svg {
  margin-right: 3px;
  color: #ffae35;
}

li.rt_line {
  color: #ccc;
  border: 0.6px solid;
  padding: 0 !important;
  height: 20px;
}

.rt_product_list_heading h2 {
  text-align: left;
  font-size: 18px;
  margin-top: 20px;
  font-weight: 500;
  color: #000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rt_product_list_heading p {
    text-align: left;
    font-size: 20px;
    margin-top: 10px;
    font-weight: 700;
    margin-bottom: 0;
}
.rt_product_list_heading p del {
  font-size: 16px;
  margin-top: 10px;
  font-weight: 500;
  margin-bottom: 0;
  color: #ccc;
}

.rt_pagination
  .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #00233c !important;
  color: #fff !important;
}

.rt_pagination ul {
  justify-content: center;
  margin: 20px 0 !important;
}

/*Product List Css*/

/*Responsive Css*/
@media (min-width: 1200px) and (max-width: 1440px) {
  .rt_product_list_content img {
    height: 200px;
}
  .rt_product_list_heading h5 {
    font-size: 14px;
}
  .rt_drop_content_main
    .list-group
    .list-group-item
    .form-check
    label.form-check-label {
    top: 0px;
    font-size: 12px;
  }
  .rt_product_list_heading h2 {
    font-size: 16px;
  }
  .rt_product_list_heading p {
    font-size: 18px;
    margin-top: 5px;
  }
  .rt_productlist_section_bg {
    padding: 3rem 0;
  }
  .rt_productlist_banner_content h1 {
    font-size: 26px;
    margin-bottom: 0;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .rt_product_list_heading h2 {
    font-size: 16px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .rt_product_list_heading h5 {
	font-size: 15px;
}
  .rt_productlist_banner_content {
    background-color: #fff;
    border-radius: 10px;
    width: 30%;
  }
  .rt_productlist_shortby ul li h5 {
    padding: 7.5px 5px 5px 5px;
  }
  .rt_productlist_filter ul {
    justify-content: center;
  }
  .rt_productlist_result {
    margin-bottom: 15px;
  }
  .rt_productlist_shortby ul {
    justify-content: center;
  }
  .rt_productlist {
    padding: 2.5rem 0;
  }
  .rt_product_list_heading h2 {
    font-size: 16px;
  }
  .rt_productlist_banner_content h1 {
    margin-bottom: 0;
    font-size: 26px;
  }
}

@media (min-width: 320px) and (max-width: 575px) {
  .rt_productlist_banner_content {
    width: 80%;
  }

  .rt_product_list_heading h2 {
    font-size: 18px;
  }

  .rt_product_list_heading p {
    text-align: left;
    font-size: 16px;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .rt_product_list_heading h5 {
    font-size: 14px;
}
.rt_productlist_banner_content h1 {
  font-size: 22px;
}

  .rt_quantity1 button, .rt_quantity1 button:active, .rt_quantity1 button:focus, .rt_quantity1 button:hover {
	font-size: 22px;
	padding: 0 7px;
	line-height: 24px;
}
.rt_productlist_add_to_cart_button {
	padding: 10px 30px;
	font-size: 14px;
}
  .rt_drop_content_main {
    width: 100%;
    margin-top: 2px;
  }
  .rt_dropfilter_main button {  
    width: 100%;
  }
  .rt_dropfilter_item_main {
    width: 100%;
  }
  .rt_productlist_filter.price_brand ul {
    display: block;
    border: 1px solid #cfcfcf;
    width: 100%;
  }
  .rt_productlist_shortby {
    margin-top: 15px;
  }
  .rt_drop_filet_main button.rt_drop_btn {
    width: 100%;
    border: none;
  }
  .rt_productlist_filter.price_brand ul {
    border: 0px solid #cfcfcf;
  }
  .rt_productlist_result {
    margin-top: 12px;
  }
  .rt_productlist_filter ul li select,
  .rt_productlist_filter ul li select:active,
  .rt_productlist_filter ul li select:hover,
  .rt_productlist_filter ul li select:focus {
    border-radius: 5px !important;
    border-color: transparent !important;
  }
  .rt_productlist_shortby ul li select {
    text-align: right;
  }
  .rt_productlist_shortby ul {
    justify-content: center;
  }
  .rt_productlist_shortby ul li:last-child {
    width: 74%;
  }
  .rt_productlist_shortby ul li:first-child {
    width: 34%;
    padding: 10px 0 10px 10px;
  }
  .rt_productlist {
    padding: 2rem 0;
  }

  .rt_productlist_filter ul {
    justify-content: center;
  }

  .rt_productlist_filter ul li {
    margin-bottom: 10px;
  }

  .rt_productlist_filter ul {
    display: block;
    align-items: center;
  }
  .rt_productlist_result p {
    text-align: center;
  }
}

@media(min-width:1200px) and (max-width:1450px){
  .rt_image_overlay_middle {
    padding: 12px;
}
.rt_productlist_add_to_cart_button {
	padding: 8px 20px;
	margin-top: 12px;
}
.rt_quantity1 button, .rt_quantity1 button:active, .rt_quantity1 button:focus, .rt_quantity1 button:hover {
	font-size: 24px !important;
	line-height: 28px !important;
}
.rt_wishlist_icon {
	width: 30px;
	height: 30px;
}
.rt_wishlist_icon span svg {
	width: 20px;
	height: 25px;
	line-height: 18px;
	margin-top: 4px;
}
}

@media(max-width:1024px){
.rt_image_overlay_middle {
	transition: .5s ease;
	opacity: 1;
	position: unset !important;
	top: 73px !important;
	left: 41px;
	transform: none;
	-ms-transform: none;
	padding: 0;
	right: 0;
	background-color: transparent !important;
}
.rt_Qyantity {
	display: none;
}
.rt_quantity1 {
	width: 100% !important;
}
.add_to_cart_box {
	display: flex !important;
	justify-content: center !important;
}
/* .rt_productlist_add_to_cart_button {
	font-size: 14px;
	margin-left: 15px;
	margin-top: 0;
	line-height: 5px;
  display: none;
} */
.rt_quantity1 .form-control {
	font-size: 13px;
	line-height: 20px;
}
.rt_add_to_cart_icon_plist svg {
	font-size: 40px;
	margin-left: 15px;
	color: #000000db;
}

.rt_quantity1 button, .rt_quantity1 button:active, .rt_quantity1 button:focus, .rt_quantity1 button:hover {
	font-size: 23px !important;
	padding: 0 7px;
	line-height: 25px !important;
}
}

@media(min-width:1025px){
 .rt_add_to_cart_icon_plist{
    display: none;
  }
}
 


/*Responsive Footer Css*/
