.rt_login_section {
    padding: 20px 0px;
}

.rt_login_form_main {
    background: white;
    padding: 0px 25px;
}

.rt_login_creating_b_main {
    position: relative;
    background: #00233C;
    z-index: 9;
    overflow: hidden;
}

.rt_login_form_main h2 {
    font-weight: 600;
    font-size: 29px;
}

.rt_login_form_main p {
    font-weight: 300;
    font-size: 18px;
}

.rt_login_form input,.rt_login_form input:hover,.rt_login_form input:active,.rt_login_form input:focus {
    background: #f0f0f0;
    border-radius: 7px !important;
    height: 60px;
    box-shadow: none;
    outline: none;
    border-color: #ededed;
}

/* .rt_login_form .form-control:focus {
    color: #212529;
    background-color: #6DA78040;
    border-color: #87ae94;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(166 218 183);
} */

.rt_login_btn,
.rt_login_btn:hover,
.rt_login_btn:active,
.rt_login_btn:focus {
    width: 100%;
    height: 55px;
    font-weight: 300;
    font-size: 22px;
    background: #00233a;
    border-color: #00233a;
    outline: none;
    box-shadow: none;
    margin-top: 20px;
    border-radius: 10px;
}
.rt_login_btn:disabled{
    width: 100%;
    height: 55px;
    font-weight: 300;
    font-size: 22px;
    background: #00233a !important;
    border-color: #00233a !important;
    outline: none;
    box-shadow: none;
    margin-top: 20px;
    border-radius: 10px;
}

.rt_login_creating_b_main {
    padding: 80px 50px;
    text-align: center;
}

.rt_login_creating_b_main h2 {
    color: white;
    font-weight: 600;
    font-size: 42px;
    line-height: 40px;
}

.rt_login_creating_box_main {
    background: #FFFFFF;
    box-shadow: 0px 4px 24px #0c446a;
    border-radius: 10px;
    padding: 50px 20px;
}

.rt_login_creating_box_main img {
    margin-bottom: 10px;
}

.rt_login_creating_box_main p {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    margin: 0;
}

.rt_login_creat_an_account_btn,
.rt_login_creat_an_account_btn:hover,
.rt_login_creat_an_account_btn:active,
.rt_login_creat_an_account_btn:focus {
    font-weight: 500;
    font-size: 18px;
    margin-top: 50px;
    background: transparent;
    border-color: white;
    padding: 10px 30px;
    border: 2px solid #FFFFFF;
    border-radius: 10px;
    box-shadow: none !important;
    outline: none !important;
}

.rt_login_main_bg.row {
    background: white;
    align-items: center;
}

.rt_login_title_text p {
    color: #FFFFFF;
    font-weight: 5;
    font-size: 20px;
    margin-bottom: 40px;
}

.rt_login_creating_b_main:before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    background-image: url('../Images/login_bg2.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    mix-blend-mode: luminosity;
    opacity: 0.75;
    filter: drop-shadow(0px 4px 44px rgba(0, 0, 0, 0.04));
    width: 100%;
    height: 100%;
    z-index: -9;
}

.rt_login_form_main.rt_register {
    padding: 80px 25px;
}

.rt_login_title_text.rt_register_title_text p {
    margin-bottom: 0;
}

.rt_login_title_text.rt_register_title_text button {
    margin-top: 20px;
    width: 90%;
}

.rt_login_creat_an_account_btn a {
    color: inherit;
}

.rt_login_creating_b_main.rt_register_creating_b_main {
    height: 837px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px 0px;
}
.rt_login_form input {
    font-family: 'Nunito', sans-serif !important;
}
.rt_login_form label {
    font-family: 'Nunito', sans-serif !important;
    font-weight: 600;
    font-size: 14px;
}
@media (min-width:1200px) and (max-width:1440px) {
    .rt_login_creating_box_main p {
        font-size: 14px;
        line-height: 20px;
    }
    .rt_login_form input, .rt_login_form input:hover, .rt_login_form input:active, .rt_login_form input:focus {
        height: 45px;
    }
    .rt_login_creating_box_main {
        padding: 30px 20px;
    }
    .rt_login_btn, .rt_login_btn:hover, .rt_login_btn:active, .rt_login_btn:focus {
        height: 50px;
        font-weight: 400;
        font-size: 20px;
    }
    .rt_login_form_main.rt_register {
        padding: 30px 40px;
    }
    .rt_login_title_text p {
        margin-bottom: 30px;
    }

    .rt_login_creat_an_account_btn,
    .rt_login_creat_an_account_btn:hover,
    .rt_login_creat_an_account_btn:active,
    .rt_login_creat_an_account_btn:focus {
        font-weight: 500;
        font-size: 21px;
        margin-top: 40px;
    }

    .rt_login_creating_b_main h2 {
        font-size: 36px;
        line-height: 48px;
    }


}
/*Register Page Css*/
.rt_forget_text_box{
    display: flex;
    justify-content: right;
    text-decoration: underline;
}
.rt_forget_text_box:hover {
    text-decoration: none;
}
.rt_forget_text_box a {
    color: black;
}
.rcs_signup_link p {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    margin: 0;
    padding: 40px 0 0;
}    
.rcs_signup_link a {
    font-size: 16px;
    font-weight: 600;
    padding: 0 4px;
}
.rcs_account_content_left {
    padding: 20px;
    border-radius: 3px;
}
.rcs_account_content_left .MuiFormControl-root {
    width: 100%;
    margin-bottom: 15px;
}
.rcs_custom_padding {
    padding: 0 5px !important;
}
.alert.alert-info.text-left {
    margin-top: 20px;
    padding: 20px;
}
.alert.alert-info.text-left span {
    padding-left: 5px;
    margin-top: -2px;
}
/*Register Page Css*/ 
.rcs_signup_link_error {
    position: relative;
}
.rcs_signup_link_error p {
    position: absolute;
    top: 0;
    padding: 10px 0 0;
    font-size: 13px;
    left: 0;
    right: 0;
}
/* ul.MuiList-root.MuiList-padding.MuiMenu-list.css-6hp17o-MuiList-root-MuiMenu-list li {
    display: block !important;
    padding: 10px !important;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    text-align: left !important;
} */
@media (min-width:1024px) and (max-width:1199px) {
    .rt_login_creating_b_main.rt_register_creating_b_main {
        height: 850px;
    }
    .rt_login_main_bg.row {
        margin: 0px 5px;
    }
    .rt_login_creating_b_main h2 {
        color: white;
        font-weight: 600;
        font-size: 36px;
        line-height: 50px;
    }

    .rt_login_creating_box_main p {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        margin: 0;
    }

    .rt_login_title_text p {
        font-size: 16px;
        margin-bottom: 30px;
    }

    .rt_login_creat_an_account_btn,
    .rt_login_creat_an_account_btn:hover,
    .rt_login_creat_an_account_btn:active,
    .rt_login_creat_an_account_btn:focus {
        font-size: 20px;
    }

    .rt_login_creating_box_main {
        padding: 15px 15px;
        height: 115px;
    }

    .rt_login_section {
        padding: 15px 0px;
        padding-bottom: 0;
        
    }

    .rt_login_creating_box_main img {
        width: 25%;
    }
}



@media (min-width:768px) and (max-width:1023px) {
    .rt_login_form_main {
        background: white;
        padding: 50px 25px;
    }
    .rt_login_creating_b_main {
        padding: 50px 50px;

    }
    .rt_login_creating_box_main p {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        margin: 0;
    }
    .rt_login_section {
        padding: 10px 0px;
    }
    .rt_login_creating_box_main {
        padding: 15px 15px;
        height: 115px;
    }

    .rt_login_title_text p {
        font-size: 16px;
        margin-bottom: 30px;
    }

    .rt_login_creating_b_main h2 {
        font-size: 30px;
        line-height: 38px;
    }

    .rt_login_creat_an_account_btn {
        margin-top: 40px;
        font-size: 18px;
    }
    .rt_login_creating_b_main.rt_register_creating_b_main {
        height: 100%;
    }

}

@media (min-width:320px) and (max-width:767px) {
    .rt_login_btn, .rt_login_btn:hover, .rt_login_btn:active, .rt_login_btn:focus {
        height: 55px;
        font-weight: 400;
        font-size: 20px;
    }
    .rt_login_form_main {
        padding: 50px 25px;
    }
    .rt_login_creating_box_main {
        padding: 50px 20px;
        margin-bottom: 20px;
    }
    .rt_login_creating_box_main p { 
        font-size: 16px;
        line-height: 24px;
    }
    .rt_login_creating_b_main {
        padding: 50px 20px;
        text-align: center;
    }
    .rt_login_title_text p {
        font-size: 16px;
        margin-bottom: 25px;
        line-height: normal;
    }
    .rt_login_creating_b_main h2 {
        font-size: 22px;
        line-height: 28px;
        letter-spacing: 2px;
    }
    .rt_login_creat_an_account_btn, .rt_login_creat_an_account_btn:hover, .rt_login_creat_an_account_btn:active, .rt_login_creat_an_account_btn:focus {
        font-size: 15px;
        margin-top: 15px;
        width: 100%;
    }
    .rt_login_section {
        padding: 0px 0px;
        margin-top: 10px;
    }
    .rt_login_form_main.rt_register {
        padding: 50px 25px;
    }
    .rt_login_creating_b_main.rt_register_creating_b_main {
        height: 100%;
        padding: 50px 0px;
    }
    .rt_login_form_main h2 {
        font-weight: 600;
        font-size: 24px;
    }
    .rt_login_form label.form-label {
        font-weight: 400;
        font-size: 14px;
    }
    .rt_login_creating_b_main:before{
        display: none;
    }
}