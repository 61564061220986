/*Contact Us*/
.rt_contact_section {
    background: #FFFFFF;
    box-shadow: 0px 0px 44px rgb(0 0 0 / 6%);
    border-radius: 10px;
    padding: 50px;
}

.rt_contact_section h2 {
    font-size: 40px;
    line-height: 27px;
    font-family: 'Limerick' !important;
    text-transform: capitalize;
}

.rt_contact_section p {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #000000;
    opacity: 0.5;
    margin: 30px 0;
    width: 70%;
}

.rt_contact_section ul {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 20px !important;
}

.rt_contact_section ul li {
    background-color: #f9f9f9;
    border-radius: 10px;
    padding: 15px 15px;
    font-size: 16px;
    margin-right: 15px;
}

.rt_contact_section ul li span {
    border-left: 1px dashed rgba(0, 0, 0, 0.1);
    padding-left: 10px;
}

.rt_contact_section ul li svg {
    margin: 0 10px;
    font-size: 25px;
}

.rt_contact_content_left h2 {
    font-size: 40px;
    line-height: 27px;
    font-family: 'Limerick' !important;
    text-transform: capitalize;
    margin-bottom: 35px;
}

.rt_contact_content_left label {
    font-weight: 600;
    font-size: 14px;
    font-family: 'Nunito', sans-serif !important;
}

.rt_contact_content_left input {
    font-weight: 600;
    font-size: 14px;
    font-family: 'Nunito', sans-serif !important;
}

.rt_contact_content_left textarea,
.rt_contact_content_left textarea:focus,
.rt_contact_content_left textarea:active {
    background: #f0f0f0;
    padding: 10px;
    border-color: #ededed;
    outline: none;
    box-shadow: none;
    font-family: 'Nunito', sans-serif !important;
}

.rt_contact_content_left {
    box-shadow: none;
    border-left: 1px solid #ddd;
    border-radius: 0;
    padding: 0 35px 25px 35px;
}

.rt_contact_content_left button {
    margin-top: 30px;
}
.rt_contact_content_left button:disabled{
    background: #00233a !important;
    border-color: #00233a !important;
}
.rt_contact_content_left label {
    font-weight: 600;
    font-size: 14px;
}

.rt_contact_content_left input,
.rt_contact_content_left input:focus,
.rt_contact_content_left input:active {
    padding: 10px;
    border-color: #ededed;
    outline: none;
    box-shadow: none;
    background: #f0f0f0;
    border-radius: 7px !important;
    height: 60px;
}

.rt_contact_content_left select,
.rt_contact_content_left select:focus,
.rt_contact_content_left select:active {
    padding: 10px;
    border-color: #ededed;
    outline: none;
    box-shadow: none;
    margin-bottom: 15px;
    font-family: 'Nunito', sans-serif !important;
    background: #f0f0f0;
}

.rt_contact_content_left .form-check {
    margin: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*Contact Us*/
/*About Us*/
.rt_about_logo_bg img {
    width: 100%;
}

.rt_about_section {
    padding: 5rem 0;
}

.rt_about_content p,
.rt_about_content1 p {
    font-size: 18px;
}

.rt_about_content {
    padding-right: 30px;
}

.rt_about_content1 {
    padding-left: 30px;
}

/*About Us*/
/*FAQ Us*/
.rt_faq_accordion .css-o4b71y-MuiAccordionSummary-content.Mui-expanded .css-ahj2mt-MuiTypography-root,
.rt_faq_accordion .css-o4b71y-MuiAccordionSummary-content .css-ahj2mt-MuiTypography-root {
    font-weight: bold;
}

/*FAQ Us*/

/* Success page css */
.rt_succes_main {
    background: white;
    border-radius: 10px;
    padding: 50px 20px;
    text-align: center;
    margin: 3rem 0px;
    box-shadow: 0 0 44px #e9f5ff;
}

/* Success page css end */


.rt_no_data {
    background: white;
    padding: 110px 0px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
}
.rt_no_data img {
    width: 100%;
}

.rt_no_data h4 {
    margin-top: 10px;
    color: #1d1d1d;
}
ul.rt_header_menu_skeleton li {
    width: 120px;
}
ul.rt_header_menu_skeleton {
    display: flex;
}
/*Responsive css*/

@media (max-width:767px) {
    .rt_succes_img p {
        font-size: 14px;
    }
    .rt_succes_img img {
        width: 100%;
    }
    .rt_succes_main {
        margin: 1.5rem 0px;
        padding: 30px 20px;
    }
}


@media (min-width:768px) and (max-width:1199px) {
    .rt_contact_section h2 {
        font-size: 30px;
        line-height: 27px;
    }
    .rt_contact_section p {
        font-size: 18px;
        margin: 20px 0;
        width: 100%;
    }
    .rt_contact_section ul {
        display: block;
        margin-bottom: 20px !important;
    }
    .rt_contact_section ul li {
        font-size: 16px;
        margin-bottom: 15px;
    }
    .rt_contact_content_left h2 {
        font-size: 28px;
        margin-bottom: 15px;
    }
    .rt_contact_section {
        padding: 30px 30px;
    }
    .rt_contact_content_left {
        padding: 0 0px 25px 35px;
    }

}

@media (min-width:320px) and (max-width:575px) {
    .rt_about_content {
        padding-right: 0px;
    }

    .rt_about_content1 {
        padding-left: 0;
    }

    .rt_about_content p,
    .rt_about_content1 p {
        font-size: 14px;
    }

    .rt_about_logo_bg img {
        margin-bottom: 25px;
    }

    .rt_about_section {
        padding: 2rem 0 0;
    }

    .rt_contact_section {
        padding: 20px 10px;
    }

    .rt_contact_section p {
        font-size: 16px;
        line-height: 19px;
        width: 100%;
    }

    .rt_contact_section ul {
        display: block;
    }

    .rt_contact_section ul li {
        padding: 15px 15px;
        font-size: 14px;
        margin-bottom: 15px;
    }

    .rt_contact_section ul li svg {
        font-size: 20px;
    }

    .rt_contact_content_left {
        border-left: none;
    }

    .rt_contact_section h2 {
        font-size: 30px;
    }

    .rt_contact_content_left {
        padding: 30px 0;
    }
}

/*Responsive css*/