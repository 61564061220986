/*Footer Css*/
    .rt_footer {
        padding: 5rem 0 0;
    }
  
    .rt_footer_content h2 {
        font-size: 22px;
        font-weight: 500;
        margin-bottom: 20px;
    }  
    p.tr_mobile_para a {
        color: black;
    }  
    .rt_footer_content ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }    
    .rt_footer_content li a {
        margin: 10px 0;
        display: block;
        color: #00233C;
        font-size: 16px;
        text-decoration: none;
        font-weight: 500;
    }    
    .rt_footer_content1 ul li {
        position: relative;
    }
    .rt_footer_content1 li a svg {
        position: absolute;
        top: 5px;
        left: 0;
    }    
    .rt_footer_content1 li a {
        padding-left: 25px;
    }
    .rt_footer_content_badge ul {
        display: flex;
        flex-wrap: wrap-reverse;
        justify-content: center;
    }
    .rt_footer_content_left {
        margin-top: -15px;
    }    
    .rt_footer_content_left img {
        margin-bottom: 5px;
    }    
    .rt_footer_content_left p {
        font-size: 15px;
        font-weight: 500;
        padding-right: 55px;
    }
    .rt_footer_content_left p a {
        color: #000;
        text-decoration: none;
    }
    .rt_footer_bottom {
        border-top: 1px solid rgb(0 0 0 / 10%);
        padding: 25px 0;
    }
    .rt_footer_bottom_content p {
        margin: 0;
        text-align: center;
        font-size: 15px;
        font-weight: 500;
    }
    .rt_footer_bottom_content ul {
        display: flex;
        margin: 0;
        padding: 0;
        justify-content: right;
        align-items: center;
    }    
    .rt_footer_bottom_content ul li {
        background-color: #fff;
        padding: 5px;
        margin: 0 5px;
        width: 35px;
        height: 35px;
        text-align: center;
        border-radius: 5px;
    }
    .rt_footer_bottom_content ul li:hover,.rt_footer_bottom_content ul li:active {
        background-color: #000;
    }    
    .rt_footer_bottom_content ul li:hover svg,.rt_footer_bottom_content ul li:active svg {
        color: #fff;
    }
    .rt_footer_bottom_content ul li svg {
        font-size: 20px;
        color: #000;
    }
    .rt_footer_bottom_content1 ul {
        display: flex;
        justify-content: start;
        align-items: center;
    }    
    .rt_footer_bottom_content1 ul li img {
        width: 60px;
        margin-right: 5px;
    }
/*Footer Css*/
/*Responsive Footer Css*/
    @media (min-width: 320px) and (max-width: 575px){ 
        .rt_footer_mobile {
            padding: 30px 0;
        }
        .rt_footer_mobile img {
            margin: 0 auto 15px;
            display: block;
        }
        .rt_footer_bottom_content1 ul {
            justify-content: center;
        }
        .rt_footer_bottom_content1,.rt_footer_bottom_content {
            margin-bottom: 10px;
        }
        .rt_footer_mobile .css-1pnmrwp-MuiTypography-root {
            color: rgba(0, 0, 0, 1);
            font-weight: 800;
        }
        .tr_mobile_para,.rt_footer_content li a {
            font-size: 14px;
        }        
    }    
    @media (min-width:768px) and (max-width:991px) {
        .rt_footer_mobile {
            padding: 2rem 0;
        }
        .rt_footer_mobile img {
            margin-bottom: 20px;
        }
        .rt_footer_bottom_content1 {
            display: none;
        }
        .rt_footer_bottom_content {
            margin-top: 10px;
        }
        .rt_footer_bottom {
            padding: 15px 0;
        }
    }
    @media (min-width:992px) and (max-width:1199px) {
        .rt_rugsize_content {
            height: 220px;
            width: 220px;
        } 
        .rt_area_content_main .slick-prev {
            left: 77%;
        } 
        .rt_rugsize_content img {
            width: 160px;
        }
        .rt_area_section {
            padding: 2rem 0;
        } 
        .rt_footer_content_badge ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: start;
        } 
        .rt_footer_bottom_content1 {
            display: block !important;
        }   
        .rt_footer_content.rt_footer_content_badge ul li {
            width: 118px;
            margin: auto;
        } 
        .rt_footer_content_left img {
            margin-bottom: 10px;
        }
        .rt_footer_content_left p {
            padding-right: 0px;
            text-align: center;
            width: 95%;
            margin: auto;
            margin-bottom: 15px;
        }
        .rt_footer_content h2 {
            margin-bottom: 10px;
        }
        .rt_f_logo{
            width: 40%;
            margin: auto;
        }
    }
    @media (min-width:1150px) and (max-width:1350px) {
        .rt_footer_content_left img {
            width: 190px;
        }
        .rt_footer_content_left p {
            font-size: 13px;
        }
        .rt_footer_content h2 {
            font-size: 18px;
        }
        .rt_footer_content li a {
            font-size: 14px;
        }
        .rt_footer_content_badge ul li img {
            width: 70px;
        }
        .rt_footer_bottom_content p {
            font-size: 13px;
        }
        .rt_footer_bottom_content1 ul li img {
            width: 50px;
        }
        .rt_footer_bottom_content ul li {
            width: 30px;
            height: 30px;
            line-height: 17px;
        }
        .rt_footer_bottom_content ul li svg {
            font-size: 14px;
        }
    }

/*Responsive Footer Css*/