.rt_custome_header_container {
    max-width: 1520px;
    width: 100%;
}

.rt_header_main {
    transition: ease-out 0.5s;
}

.rt_menu_ovrlay {
    background: #00000042;
    backdrop-filter: blur(2px);
    width: 100%;
    height: 100vh;
    /* float: left; */
    position: absolute;
    z-index: 9;
    margin-top: 20px;
}

.rt_header_main.is-sticky .rt_menu_ovrlay{
    margin-top:0px;
}

/* .rt_menu_hover_overlay {
    top: 0;
    left: 0;
    width: 100%;
    background: #00000036;
    z-index: 99;
    transition: all 0.5s;
    height: 100vh;
    float: left;
    position: absolute;
} */

.rt_header_main.is-sticky {
    position: fixed;
    background: #f2f9ff;
    width: 100%;
    top: 0;
    padding: 5px 0px 2px 0px;
    transition: ease-in 0.5s;
    z-index: 999;
    box-shadow: 0px 0px 5px rgb(231 231 231);
}

.rt_header_main_d {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.rt_header_main {
    padding-top: 1.5rem;
    padding-bottom: 20px;
}

.rt_header_menu ul .rt_header_menu_parent_li {
    display: inline-block;
}

.rt_header_menu {
    position: relative;
    z-index: 99;
}

.rt_header_menu_first_ul .rt_header_menu_parent_li .rt_mega-menu_parent_a {
    padding: 2rem 1.1875rem;
    padding-top: 0;
    color: #131951;
    font-weight: 500;
    font-size: 18px;
}
.rt_header_search_main .input-group.input-group-lg span svg {
    cursor: pointer;
}
.rt_header_icon ul li {
    display: inline-block;
    padding: 0px 7px;
}

.rt_header_icon ul li svg {
    font-size: 24px;
}

.rt_mega_menu_item_main {
    display: flex;
    position: absolute;
    width: 100%;
    border-radius: 10px;
    margin-top: 1px;
    top: 45px;
    visibility: hidden;
    z-index: 9999;

}

li.rt_header_menu_parent_li.rt_mega_submenu:hover .rt_mega_menu_item_main {
    visibility: visible;
    left: 0;
}

.rt_mega_menu_item_list {
    width: 60%;
    padding: 30px 15px;
    box-shadow: 0px 0px 7px #c4e4ff;
    background: white;
    border-radius: 10px 0px 0px 10px;
}

.rt_mega_menu_item_img {
    width: 50%;
    border-radius: 0px 10px 10px 0px;
    overflow: hidden;
}

.rt_mega_menu_item_img img {
    width: 100%;
    height: 100%;
}

.rt_mega_menu_item_img {
    width: 50%;
}

.rt_mega_menu_item_list h3 {
    font-weight: 500;
    font-size: 25px;
}

.rt_mega_menu_item_list ul li {
    padding: 5.5px 10px;
    padding-right: 0;
}

li.rt_header_menu_child_li a {
    font-weight: 500;
    font-size: 18px;
    color: #000000;
    text-transform: capitalize;
}

.rt_mega_menu_item_list ul {
    float: left;
    width: 60%;
}

li.rt_header_menu_child_li a:before {
    display: none;
}

li.rt_header_menu_parent_li.rt_mega_submenu:hover .rt_mega-menu_parent_a:before {
    display: block;
}

li.rt_header_menu_parent_li.rt_mega_submenu .rt_mega-menu_parent_a {
    position: relative;
}

li.rt_header_menu_parent_li.rt_mega_submenu .rt_mega-menu_parent_a:before {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 25px;
    height: 25px;
    background: #ffffff;
    transform: rotate(45deg);
    border-radius: 2px;
    display: none;
    z-index: 999999;
}

.rt_header_icon ul li svg {
    cursor: pointer;
}

ul.rt_header_menu_first_ul {
    margin: 0;
}

.rt_breadcrumb li.MuiBreadcrumbs-separator {
    display: none;
}

.rt_breadcrumb li.MuiBreadcrumbs-li {
    margin-right: 12px;
}

.rt_breadcrumb li.MuiBreadcrumbs-li a {
    color: black;
    font-weight: 400;
}

.rt_breadcrumb li.MuiBreadcrumbs-li a svg {
    font-size: 22px;
}

.rt_breadcrumb nav {
    padding: 15px 0px 0px 0px;
}

/* .rt_search_canvas {
    height: 20vh;
    background: #ffffffed;
    z-index: 9999;
} */

.offcanvas-backdrop {
   background-color: #0000008f;
}
.rt_search_canvas {
    background: transparent;
    z-index: 9999;
    width: 26%;
    left: inherit;
    right: 11.1%;
    top: 7.8%;
    height: max-content;
    padding-bottom: 10px;
    box-shadow: none;
    border: none;
}
.rt_search_canvas .offcanvas-header {
    padding: 0px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    display: none;
}

.rt_search_canvas .offcanvas-body {
    padding: 0;
    padding-top: 0px;
}

.rt_search_canvas .offcanvas-title.h5 h3 {
    margin: 0;
    color: #131951;
    font-size: 20px;
    margin: 0;
    font-weight: 500 !important;
    padding: 0;
    margin-bottom: -10px;
}

.rt_header_search_main .input-group.input-group-lg {
    height: 50px;
}

.rt_header_search_main .input-group.input-group-lg input {
    background: transparent;
    border-color: #131951;
    border-radius: 0;
    box-shadow: none;
    outline: none;
    background: white;
}
.rt_mobile_menu_content_main button.accordion-button {
    padding: 1rem 0.57rem;
}
.rt_header_search_main .input-group.input-group-lg span {
    background: #131951;
    border-color: #131951;
    color: white;
    border-radius: 0;
    padding: 10px 20px;
    font-size: 28px;
}

.rt_search_canvas button.btn-close {
    outline: none;
    box-shadow: none;
    font-size: 22px;
    position: relative;
    top: 3px;
}

.rt_header_icon li.rt_header_mobile_menu_icon {
    display: none;
}

.rt_mobile_menu_main {
    width: 360px;
}

.rt_mobile_menu_content_main .accordion-button:not(.collapsed)::after {
    background-image: url("../Images/header/upArrow.svg");
}

/********************* media css **********************/
@media (min-width:1500px) and (max-width:1600px) {
    .rt_header_search_main .input-group.input-group-lg {
        height: 50px;
    }
    
    .rt_search_canvas {
        width: 26%;
        left: inherit;
        right: 1.5%;
        top: 12%;
    }
}


@media (min-width:1200px) and (max-width:1440px) {
    .rt_menu_ovrlay {
        margin-top: 16px;
    }
    .rt_header_search_main .input-group.input-group-lg span svg {
        font-size: 18px;
    }
    .rt_search_canvas {
        padding-bottom: 15px;
        top: 9%;
        right: 5%;
    }
    .rt_header_search_main .input-group.input-group-lg input.form-control {
        height: 100%;
    }
    .rt_search_canvas .offcanvas-title.h5 h3 {
        font-size: 18px;
    }
    .rt_search_canvas .offcanvas-body {
        padding-top: 0px;
    }
    .rt_header_logo img {
        width: 100%;
    }
    .rt_header_logo {
        width: 200px;
    }
    .rt_search_canvas .offcanvas-header {
        padding-bottom: 5px;
    }
    .rt_search_canvas button.btn-close {
        font-size: 16px;
    }

    .rt_header_search_main .input-group.input-group-lg {
        height: 40px;
    }

    .rt_header_search_main .input-group.input-group-lg span {
        padding: 10px 20px;
        font-size: 22px;
    }

    .rt_custome_header_container {
        max-width: 1180px;
        width: 100%;
    }

    .rt_header_menu_first_ul .rt_header_menu_parent_li .rt_mega-menu_parent_a {
        padding: 1rem 0.7875rem;
        font-size: 14px;
    }
    .rt_header_icon ul li svg {
        font-size: 20px;
    }
    .rt_header_main {
        padding-top: 0.5rem;
        padding-bottom: 1rem;
    }

    .rt_mega_menu_item_main {
        top: 38px;
    }

    li.rt_header_menu_parent_li.rt_mega_submenu .rt_mega-menu_parent_a:before {
        bottom: -15px;
    }

    .rt_mega_menu_item_list h3 {
        font-size: 18px;
    }

    li.rt_header_menu_child_li a {
        font-size: 14px;
    }

    .rt_mega_menu_item_list ul li {
        padding: 1px 10px;
        padding-right: 0;
    }

    .rt_banner_slider_main_text_content h1 {
        font-size: 36px;
    }

    .rt_banner_slider_main_text_content p {
        font-size: 15px;
    }

    button.rt_banner_btn a {
        font-size: 16px;
    }

    button.rt_banner_btn {
        padding: 8px 26px;
    }
}


@media (min-width:1024px) and (max-width:1199px) {
    .rt_search_canvas {
        width: 40%;
        right: 1%;
        top: 6%;
        padding-bottom: 20px;
    }
    .rt_mega_menu_item_main {
        top: 35px;
    }
    .rt_header_menu_first_ul .rt_header_menu_parent_li .rt_mega-menu_parent_a {
        padding: 1rem 0.5875rem;
        font-size: 14px;
    }

    .rt_mega_menu_item_list {
        padding: 15px 15px;
    }

    .rt_mega_menu_item_list h3 {
        font-weight: 500;
        font-size: 20px;
    }

    .rt_mega_menu_item_list ul li {
        padding: 1.8px 10px;
        padding-right: 0;
    }

    li.rt_header_menu_child_li a {
        font-size: 14px;
    }

    li.rt_header_menu_parent_li.rt_mega_submenu .rt_mega-menu_parent_a:before {
        bottom: -15px;
    }

    .rt_mega_menu_item_main {
        margin-top: 5px;
    }

}

@media (min-width:993px) and (max-width:1023px) {
    .rt_header_menu_first_ul .rt_header_menu_parent_li .rt_mega-menu_parent_a {
        padding: 1rem 0.588rem;
        font-size: 15px;
      }

      .rt_header_logo img {
        width: 100%;
      }
      .rt_header_logo {
        width: 22%;
      }
      .rt_mega_menu_item_main {
        top: 30px;
      }
      li.rt_header_menu_parent_li.rt_mega_submenu .rt_mega-menu_parent_a::before {
        bottom: -15px;
      }
      .rt_mega_menu_item_list ul li {
        padding: 2.5px 10px;
      }
      .rt_mega_menu_item_list h3 {
        font-size: 22px;
      }
      .rt_mega_menu_item_list {
        padding: 15px 15px;
      }
      li.rt_header_menu_child_li a {
        font-size: 14px;
      }
}
@media (min-width:768px) and (max-width:992px) {
    .rt_header_icon li.rt_header_mobile_menu_icon {
        display: inline-block;

    }
    .rt_search_canvas {
        width: 50%;
        right: 2%;
        top: 8%;
    }
    .rt_header_icon ul li {
        display: inline-block;
        padding: 0px 10px;

    }

    li.rt_header_menu_child_li {
        padding: 6px 10px;
    }

    .rt_mobile_menu_content_main .accordion-item {
        border: none;
        /* border-bottom: 1px solid #ededed; */
    }

    .rt_mobile_menu_main .rt_header_logo ul {
        display: none;
    }

    .rt_header_menu {
        position: relative;
        display: none;
    }

}

@media (max-width:992px) {
    .rt_mobile_single_menu_item ul li {
        padding: 1rem 1.25rem;
        border-bottom: 1px solid #ededed;
    }
    .rt_mobile_single_menu_item ul li a {
        color: black;
        font-size: 1rem;
        font-weight: 700 !important;
    }
}

@media (max-width:992px) {

    .rt_header_menu {
        position: relative;
        display: none;
    }

    .rt_header_icon li.rt_header_mobile_menu_icon {
        display: inline-block;
    }

    .rt_mobile_single_menu_item ul li {
        padding: 1rem 1.25rem;
        border-bottom: 1px solid #ededed;
    }

    .rt_mobile_menu_content_main .accordion-item {
        border: none;
        /* border-bottom: 1px solid #ededed; */
    }

    .rt_mobile_single_menu_item ul li a {
        color: black;
        font-size: 1rem;
        font-weight: 700 !important;
    }

    .rt_mobile_menu_item_main li.rt_header_menu_child_li {
        padding: 6px 10px;
    }

    .rt_mobile_menu_content_main .accordion-button:not(.collapsed) {
        color: #000000;
        background-color: #e7f1ff00;
        box-shadow: none;
        padding: 1rem 0.57rem;
        border-bottom: 1px solid #ededed;
    }
    .rt_mobile_menu_content_main .accordion-body {
        padding: 0.2rem 0.95rem;
    }

    .rt_mobile_menu_content_main .accordion-button:focus {
        border: none;
        box-shadow: none;
        box-shadow: none;
        border-bottom: 1px solid #ededed;
    }

    .rt_mobile_single_menu_item ul li a h2 {
        font-size: 1rem;
    }

    .rt_mobile_single_menu_item ul li a h2 {
        font-size: 1rem;
    }

    .rt_mobile_single_menu_item ul li a h2 {
        margin: 0;
    }
}

@media (min-width:320px) and (max-width:767px) {
    .rt_header_main.is-sticky {
        z-index: 99;
    }
    .rt_mobile_menu_main .rt_header_logo a img {
        display: none;
    }
    .rt_header_main {
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .rt_header_logo ul li {
        padding: 0px 0px;
        margin: 0px 10px;
    }

    .rt_header_logo ul {
        display: inline-flex;
    }

    .rt_header_logo {
        width: 200px;
    }

    .rt_mobile_menu_main {
        width: 260px;
    }

    li.d-mobile-none {
        display: none !important;
    }

    .rt_header_logo img {
        width: 100%;
    }

    .offcanvas-backdrop {
        z-index: 1040;
        width: 100%;
        height: 100%;
        background-color: #000;
    }

    .rt_mobile_menu_main .offcanvas-header {
        border-bottom: 1px solid #ededed;
    }

    .rt_mobile_menu_main .offcanvas-body {
        padding-top: 5px;
    }

    .rt_mobile_menu_content_main button.accordion-button.collapsed {
        padding: 1rem 0.55rem;
        border-bottom: 1px solid #ededed;
    }

    .rt_mobile_single_menu_item ul li {
        padding: 1rem 0.55rem;
    }

    .rt_mobile_menu_main button.btn-close {
        outline: none;
        box-shadow: none;
    }

    .rt_mobile_menu_item_main li.rt_header_menu_child_li {
        padding: 6px 10px;
    }

    li.rt_header_menu_child_li a {
        font-size: 14px;
    }

    .rt_mobile_menu_item_main h5 {
        font-size: 1rem;
        margin-top: 10px;
        margin-bottom: 0;
    }

    .rt_banner_slider_main_text_content p {
        font-size: 12px;
        line-height: 16px;
        margin: 10px 0px 10px 0px;
    }

    .slick-slider.rt_banner_slider_slick.slick-initialized ul.slick-dots {
        bottom: 5px;
        left: 0 !important;
        width: 65px;
        right: 0;
        margin: 0 auto !important;
    }

    .rt_banner_slider_main_text_content h1 {
        font-size: 22px;
        justify-content: center;
    }

    button.rt_banner_btn a {
        font-size: 16px;
    }

    .rt_banner_order_1 {
        order: 1;
    }

    .slick-slider.rt_banner_slider_slick.slick-initialized {
        border-radius: 0;
    }

    button.rt_banner_btn {
        padding: 8px 25px;
    }

    .rt_search_canvas .offcanvas-body {
        padding: 0px 0px;
    }

    .rt_search_canvas {
        height: 100vh;
        width: 100%;
        background: #ffffff;
        justify-content: center;
        right: 0;
        top: 0;
    }

    .rt_search_canvas button.btn-close {
        font-size: 16px;
    }

    .rt_search_canvas .offcanvas-title.h5 h3 {
        line-height: 0;
        position: relative;
        top: 5px;
    }

    .rt_categories_section {
        padding: 3rem 0;
    }

    .rt_header_icon ul li svg {
        font-size: 28px;
    }
}
.arrownone button::after{
    display: none;
}