/*Cart Banner Css*/
.rt_cart_section_bg {
    background-image: url('../Images/productList/bg_list.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 4rem 0;
    text-align: center;
}
.rt_cart_banner_content {
    background-color: #fff;
    border-radius: 10px;
    width: 20%;
    margin: 0 auto;
    display: block;
    padding: 20px 0;
}
.rt_cart_banner_content h1 {
    font-size: 30px;
    font-weight: 500;
    text-transform: uppercase;
}
.rt_cart_banner_content ul {
    display: flex;
    justify-content: center;
    align-items: center;
}
.rt_cart_banner_content li {
    padding: 0 2px;
}
.rt_cart_banner_content li a {
    color: #000;
    font-size: 14px;
    font-weight: 600;
}
/*Cart Banner Css*/
.rt_cart_content {
    padding: 5rem 0;
}
.rt_cart_content_left ul {
    display: flex;
    justify-content: start;
}
.rt_cart_content_left {
    background: #FFFFFF;
    box-shadow: 0px 0px 25px rgb(0 0 0 / 5%);
    border-radius: 13px;
    padding: 15px;
    margin-bottom: 20px;
}
.rt_cart_content_top img {
    width: 150px;
    border-radius: 10px;
    height: 200px;
    object-fit: cover;
}
.rt_cart_content_top .tag-container {
    position: absolute;
    top: 20px;
    left: -10px;
}
.rt_cart_heading {
    padding-left: 40px;
}
.rt_wishlist_icon span svg {
    width: 30px;
    height: 23px;
    line-height: 25px;
    margin-top: 4px;
    color: #000;
}   
.rt_wishlist_icon_fill span svg {
    color: #eb7c74;
} 
.rt_wishlist_icon {
    position: absolute;
    top: 15px;
    right: 16px;
    background-color: #fff;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    background: #FFFFFF;
box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}
ul.rt_rating1 {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: 100px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}    
ul.rt_rating1 li h3 {
    font-size: 16px;
    font-family: 'Nunito' !important;
    display: flex;
    font-weight: 600;
    margin: 0;
}    
ul.rt_rating1 li h5 {
    font-size: 16px;
    font-weight: 400;
    font-family: 'Nunito' !important;
    margin: 0;
}    
ul.rt_rating1 li {
    padding: 5px;
}
ul.rt_rating1 li h3 svg {
    margin-right: 3px;
    color: #FFAE35;
}
li.rt_line {
    color: #ccc;
    border: 0.6px solid;
    padding: 0 !important;
    height: 20px;
}
.rt_cart_heading h2 {
    text-align: left;
    font-size: 20px;
    margin-top: 10px;
    font-weight: 500;
    color: #000;
    font-family: 'Limerick' !important;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}
.rt_cart_heading p {
    text-align: left;
    font-size: 20px;
    margin-top: 20px;
    font-weight: 700;
    margin-bottom: 0;
}
.rt_cart_heading p del {
    font-size: 16px;
    margin-top: 10px;
    font-weight: 500;
    margin-bottom: 0;
    color: #ccc;
}
ul.rt_other_cart_item li {
    border: 1px solid rgb(0 0 0 / 10%);
    border-radius: 5px;
    margin-right: 10px;
    padding: 4px 10px;
    margin-top: 10px;
}
ul.rt_other_cart_item li p {
    margin: 0;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}
ul.rt_other_cart_item li p strong{
    margin-left: 10px;
}
ul.rt_other_cart_item li p span {
    height: 25px;
    width: 25px;
    background-color: #F87A7A;
    border-radius: 50%;
    display: inline-block;
    margin-left: 10px;
    border: 3px solid #fff;
    padding-left: 2px;
}
ul.rt_other_cart_item li p .dot {
    height: 15px;
    width: 15px;
    background-color: #F87A7A;
    border-radius: 50%;
    display: inline-block;
    margin-left: 0px;
    border: 2px solid #fff;
}
.rt_delete button {
    background: #f0f0f0;
    color: #777;
}
.rt_delete span svg:hover {
    color: #000;
}
.rt_quantity1 {
    margin: 80px 0 0;
}
.rt_delete {
    text-align: right;
}
.rt_quantity1 button, .rt_quantity1 button:active, .rt_quantity1 button:focus, .rt_quantity1 button:hover {
    background-color: #fff;
    border: 1px solid #000;
    color: #000;
    font-size: 23px;
    padding: 0 7px;
    line-height: 25px;
    outline: none !important;
    box-shadow: none !important;
}
.rt_quantity1 input,.rt_quantity1 input:focus,.rt_quantity1 input:active {
    width: 50px !important;
    border: none;
    text-align: center;
    outline: none;
    box-shadow: none;
    background-color: transparent;
}
.rt_cart_content_right {
    background: #FFFFFF;
    box-shadow: 0px 0px 25px rgb(0 0 0 / 5%);
    border-radius: 13px;
    padding: 25px 35px;   
    margin: 0 0 0 30px;
}
ul.rt_payment_details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgb(0 0 0 / 10%);
}
ul.rt_payment_details li,ul.rt_payment_total li {
    padding: 20px 0;
}
ul.rt_payment_details li p {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
}
ul.rt_payment_total li p strong {
    margin: 0;
    font-weight: 700;
    font-size: 18px;
}
ul.rt_payment_total {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.rt_checkout_btn,.rt_checkout_btn:active,.rt_checkout_btn:focus,.rt_checkout_btn:hover {
    background-color: #00233C;
    border-radius: 10px;
    width: 100%;
    padding: 13px;
    font-size: 16px;
    text-transform: uppercase;
    border: none;
    outline: none;
    box-shadow: none;
}
.rt_cart_bottom {
    margin: 30px 0 0 30px;
}
.rt_cart_bottom h2 {
    font-size: 20px;
    margin: 14px 0 5px;
}
.rt_cart_bottom p {
    margin: 0;
}
/*Cart Css*/
/*Checkout Css*/
.rt_checkout_content_left {
    background: #FFFFFF;
    box-shadow: 0px 0px 25px rgb(0 0 0 / 5%);
    border-radius: 13px;
    padding: 25px 35px;
}
.rt_checkout_content_left label {
    font-weight: 600;
    font-size: 14px;
    font-family: 'Nunito', sans-serif !important;
}
.rt_checkout_content_left input,.rt_checkout_content_left input:focus,.rt_checkout_content_left input:active {
    background-color: #fff;
    padding: 10px;
    border-color: #ededed;
    outline: none;
    box-shadow: none;
    font-family: 'Nunito', sans-serif !important;
}
.rt_checkout_content_left input.form-check-input,.rt_checkout_content_left input.form-check-input:focus,.rt_checkout_content_left input.form-check-input:active {
    background-color: #fff;
    padding: 10px;
    border-color: #ededed;
    outline: none;
    box-shadow: none;
    margin-right: 5px;
    font-family: 'Nunito', sans-serif !important;
}
.rt_checkout_content_left .form-check-input:checked {
    background-color: #00233C !important;
    border-color: #00233C !important;
}
.rt_checkout_content_left select,.rt_checkout_content_left select:focus,.rt_checkout_content_left select:active {
    background-color: #fff;
    padding: 10px;
    border-color: #ededed;
    outline: none;
    box-shadow: none;
    margin-bottom: 15px;
    font-family: 'Nunito', sans-serif !important;
}
.rt_checkout_content_left .form-check {
    margin: 20px 0;
    display: flex;

    align-items: center;
}

/* .rt_checkout_content_left.rt_chekout_new .form-check{
    justify-content: left;
} */

/*Checkout Css*/

@media (min-width: 992px) and (max-width: 1199px){ 
    .rt_cart_content_right {
        padding: 20px 20px;
        margin: 0 0 0 0px;
    }
    .rt_cart_content_right h2 {
        font-size: 1.5rem;
    }
    .rt_checkout_btn, .rt_checkout_btn:active, .rt_checkout_btn:focus, .rt_checkout_btn:hover {
        font-size: 14px;
}
ul.rt_payment_details li p {
    font-size: 15px;
}

}


/*Responsiv css*/
@media (min-width: 320px) and (max-width: 575px){ 
    .rt_cart_content_left ul {
        display: block;
        position: relative;        
    }        
    .rt_cart_heading {
        padding-left: 0;
    }
    .rt_cart_content_right {
        margin: 0;
        padding: 25px;
    }
    .rt_cart_bottom {
        margin: 30px 0 0 10px;
    }
    .rt_cart_content_top img {
        width: 100%;
    }
    ul.rt_rating1 {
        display: flex !important;
        float: left;
    }    
    .rt_quantity1 {
        margin: 30px auto 0;
        width: 50%;
        justify-content: center;
    }
    .rt_cart_heading h2 {
        font-size: 18px;
    }
    .rt_delete {
        position: absolute;
        top: 10px;
        right: 10px;
    }
    .rt_cart_banner_content {
        width: 80%;
    }
    .rt_cart_heading p {
        text-align: right;
        font-size: 20px;
        margin-top: 0;
    }
    ul.rt_other_cart_item li p span {
        padding-left: 0px;
        padding-right: 2px;
    }
    .rt_checkout_content_left {
        padding: 25px 5px;
        margin-bottom: 20px;
    }
    .rt_cart_content {
        padding: 2rem 0;
    }
}
@media (min-width:768px) and (max-width:991px) {
    .rt_cart_content_right {
        margin: 0;
    }
    .rt_cart_bottom {
        margin-left: 0;
    }
    .rt_wishlist_icon span svg {
	width: 20px;
	height: 20px;
}
.rt_wishlist_icon {
	right: 7px;
	line-height: 15px;
}
}    
@media (min-width:1150px) and (max-width:1440px) {
    .rt_cart_content_right {
        padding: 25px 25px;
        margin: 0;
    }
    .rt_cart_bottom {
        margin: 30px 0 0 0px;
    }
    .rt_wishlist_icon {
	right: 10px;
}
}
/*Responsiv css*/