@import url("https://fonts.googleapis.com/css2?family=Baskervville:ital@0;1&family=Nunito:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300&family=Poppins:ital,wght@0,200;1,200;1,300&display=swap");

@font-face {
  font-family: "Limerick";
  src: url("../Fonts/Limerick-Medium.eot");
  src: url("../Fonts/Limerick-Medium.eot?#iefix") format("embedded-opentype"),
    url("../Fonts/Limerick-Medium.woff2") format("woff2"),
    url("../Fonts/Limerick-Medium.woff") format("woff"),
    url("../Fonts/Limerick-Medium.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

/*Custom Css*/
:root {
  /* --main-primary-color: #ff8080; */
  --main-primary-color: #1b1a4a;
  --MuiDocs-navDrawer-width: 0px;
}

a:hover {
  color: #000;
  text-decoration: none !important;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  color: #212123;
  height: 101%;
}

body {
  padding: 0;
  font-family: "Limerick" !important;
  /* -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none; */
  background-color: #f2f9ff !important;
}

ul {
  padding: 0 !important;
  margin: 0 !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Limerick" !important;
}
a,
p {
  font-family: "Nunito", sans-serif !important;
}
/* Links
    -------------------------------------------------------------------------- */
a:link,
a:visited,
a:focus,
a:hover,
a:active {
  text-decoration: none;
}

a:focus,
a:hover,
a:active {
  text-decoration: none;
}

a:focus,
a:active {
  outline: none;
}

p,
ul,
ol,
dl,
pre,
table,
blockquote {
  margin-bottom: 1.5rem;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul ul,
ol ol,
dl dl,
ul ol,
ul dl,
ol ul,
ol dl,
dl ul,
dl ol {
  margin-bottom: 0;
}

@media (min-width: 1440px) {
  html {
    font-size: 90%;
  }
}

/*Custom Css*/

/*iphone css zoom*/
/*** iPhone and iOS Form Input Zoom Fixes ***/
/* Fix Input Zoom on devices older than iPhone 5: */
@media screen and (device-aspect-ratio: 2/3) {
  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  textarea.inp-pd,
  input[type="url"] {
    font-size: 16px !important;
  }

  .gemslider button.slick-prev.slick-arrow {
    left: -25px;
    font-size: 16px;
    top: 3px;
  }

  .gemslider button.slick-next.slick-arrow {
    right: -25px;
    font-size: 16px;
    top: 3px;
  }
}

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {
  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  textarea.inp-pd,
  input[type="url"] {
    font-size: 16px !important;
  }

  .gemslider button.slick-prev.slick-arrow {
    left: -25px;
    font-size: 16px;
    top: 3px;
  }

  .gemslider button.slick-next.slick-arrow {
    right: -25px;
    font-size: 16px;
    top: 3px;
  }
}

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375/667) {
  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  textarea.inp-pd,
  input[type="url"] {
    font-size: 16px !important;
  }

  .gemslider button.slick-prev.slick-arrow {
    left: -25px;
    font-size: 16px;
    top: 3px;
  }

  .gemslider button.slick-next.slick-arrow {
    right: -25px;
    font-size: 16px;
    top: 3px;
  }
}

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {
  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  textarea.inp-pd,
  input[type="url"] {
    font-size: 16px !important;
  }

  .gemslider button.slick-prev.slick-arrow {
    left: -25px;
    font-size: 16px;
    top: 3px;
  }

  .gemslider button.slick-next.slick-arrow {
    right: -25px;
    font-size: 16px;
    top: 3px;
  }
}

@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  textarea.inp-pd,
  input[type="url"] {
    font-size: 16px !important;
  }
}

@media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  textarea.inp-pd,
  input[type="url"] {
    font-size: 16px !important;
  }
}

@media only screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  textarea.inp-pd,
  input[type="url"] {
    font-size: 16px !important;
  }
}

/*iphone css zoom*/
/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #4a4a4a #f2f2f2;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #f2f2f2;
}

*::-webkit-scrollbar-thumb {
  background-color: #4a4a4a;
  border-radius: 10px;
  border: 2px solid #f2f2f2;
}

::selection {
  color: #fff;
  background: #000;
}

/* ===== Scrollbar CSS ===== */

/*Custome Css*/
.rt_custome_container.container {
  max-width: 1520px;
  width: 100%;
}

@media (min-width: 1200px) and (max-width: 1440px) {
  .rt_custome_container.container {
    max-width: 1180px;
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  *::-webkit-scrollbar {
    width: 6px;
  }
}

/*Custome Css*/
