@import "~react-image-gallery/styles/css/image-gallery.css";

section.rt_product_details_section_one {
    padding: 30px 0px 30px 0px;
}

.rt_product_details_slider_main img {
    width: 100%;
}
.ryl_details_description{
    font-size: 19px;
    text-transform: none !important;
    word-spacing: 1px;
}
.rt_product_details_title h2 {
    font-weight: 600;
    font-size: 38px;
    line-height: 52px;
    padding-right: 30px;
}

.rt_product_details_title {
    display: flex;
    justify-content: space-between;
}
input.form-control.rt_product_count_text {
    padding: 0;
}
button.rt_product_details_wishlist_icn {
    border: none;
    width: 40px;
    height: 40px;
    background: #FFFFFF;
    border: 1.4px solid rgba(0, 0, 0, 0.1);
    border-radius: 24.7922px;
    outline: none;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

button.rt_product_details_wishlist_icn svg {
    color: #cdcdcd;
    font-size: 28px;
}

.rt_product_details_text_content_main {
    width: 100%;
    margin-left: 40px;
    position: sticky;
    top: 80px;
}

/* .rt_product_details_slider_thumb .image-gallery-slides {
    display: none;
} */

.image-gallery-image {
    display: none !important;
}

.rt_product_details_slider_thumb .image-gallery-thumbnail {
    display: block;
    width: 98%;
    height: 110px;
    margin-bottom: 10px;
    border-radius: 9px;
    border: 2px solid #dedede;
    overflow: hidden;
}

.rt_product_details_slider_thumb .image-gallery-thumbnail span.image-gallery-thumbnail-inner {
    padding: 2px;
    width: 100%;
    height: 100%;
}

.rt_product_details_slider_thumb .image-gallery-thumbnail span.image-gallery-thumbnail-inner img {
    overflow: hidden;
    border-radius: 6px;
    height: 100%;
    object-fit: cover;
}

.rt_product_details_slider_thumb .image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
    border: 2px solid #00233c;
}

.rt_pd_review_btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 115px;
    padding: 5px 10px;
    background: #FFFFFF;
    box-shadow: 0px 4px 7px rgb(0 0 0 / 7%);
    border-radius: 5px;
    margin-top: 10px;
}

.rt_pd_review_btn svg {
    color: #FFAE35;
    font-size: 18px;
}

.rt_pd_review_btn p {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    margin-left: 3px;
}

.rt_pd_review_btn span {
    font-size: 14px;
    font-weight: 300;
    line-height: normal;
    padding: 0px 5px;
    margin-left: 3px;
    border-left: 1px solid #e3e3e3;
}

.rt_product_details_price_text h3 {
    font-weight: 600;
    font-size: 24px;
}

.rt_product_details_price_text h3 span {
    text-decoration: line-through;
    margin-left: 10px;
    color: #bababa;
    font-weight: 300;
    font-size: 22px;
}

.rt_product_details_price_text {
    margin-top: 25px;
}

.rt_product_details_size_slide_item_box {
    background: #fafafa;
    border: 1px solid #d6d6d6;
    border-radius: 7px;
    width: 90%;
    margin: 0 auto;
    text-align: center;
    padding: 10px 8px;
    cursor: pointer;
    
} 
.rt_product_details_size_slide_item_box p.rt_pd_p-1 {
    font-weight: 600;
    font-size: 18px;
    color: #2F2C51;
}

.rt_product_details_size_slide_item_box p.rt_pd_p-2 {
    border-top: 1px solid #d6d6d6;
    padding-top: 4px;
}

.rt_product_details_size_slide_item_box p {
    margin: 0;
}

.rt_product_details_size_slider {
    margin-top: 25px;
    border-bottom: 1px solid #d6d6d6;
    padding-bottom: 30px;
}

.rt_product_details_size_slider h3 {
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 15px;
    text-transform: uppercase;
}

.rt_product_details_size_slide_item_box {
    background: #ffffff;
    border: 1px solid #d6d6d6;
    border-radius: 7px;
    width: 100px;
    text-align: center;
    padding: 10px 8px;
    margin-bottom: 5px;
}

.rt_product_details_size_slider img.slick-arrow.slick-prev.slick-disabled {
    opacity: 0;
}

.rt_product_details_size_slider img.slick-arrow.slick-next.slick-disabled {
    opacity: 0;
}

.rt_product_details_text_content_main img.slick-arrow.slick-next {
    height: fit-content;
}

.rt_product_details_text_content_main img.slick-arrow.slick-prev {
    height: fit-content;
}

.rt_product_details_color {
    margin-top: 25px;
    border-bottom: 1px solid #d6d6d6;
    padding-bottom: 20px;
}

.rt_product_details_color label {
    color: #000000;
    font-weight: 500;
    font-size: 22px;
}

.rt_product_details_color h3 {
    font-weight: 400;
    font-size: 20px;
    text-transform: uppercase;
    margin-bottom: 15px;
}

/************* colors select radio button css ****************/
.color_label input:checked~.checkmark:before {
    background-image: url('../Images/Product/correct.png');
    background-repeat: no-repeat;
    width: 100%;
    height: auto;
    position: absolute;
    top: 12px;
    bottom: 0;
    left: 10px;
    right: 0;
    margin: auto;
    z-index: 9999;
    content: "";
}

.color_label {
    display: block;
    position: relative;
    padding-left: 50px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 35px;
}

.color_label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    left: 2px;
    top: 5px;
    width: 35px;
    height: 35px;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
}


.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* .color_label input:checked ~ .checkmark {
      border: 1px solid #262626;
  } */

.color_label .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #dddddd;
    border-radius: 50%;
}

.rt_product_details_color label.color_label {
    display: inline-block;
}

.color_label input:checked~.checkmark:after {
    display: block;
    position: absolute;
    top: 0;
    left: -3.3px;
    bottom: 0;
    margin: auto;
    right: 0;
    background: #ffffff;
    border: 1px solid #cacaca;
    width: 46px;
    height: 46px;
    z-index: -9;
    border-radius: 50%;
}
.rt_product_details_shortby .form-select:focus {
    border-color: #ced4da;
}
/************* colors select radio button css ****************/

.rt_product_details_select_type {
    margin-top: 25px;
}

.rt_product_details_select_type h3 {
    font-weight: 400;
    margin-bottom: 15px;
    font-size: 20px;
    text-transform: uppercase;
}

ul.rt_product_details_select_type_ul li {
    display: inline-block;
    margin-right: 10px;
}

ul.rt_product_details_select_type_ul li button,
ul.rt_product_details_select_type_ul li button:hover,
ul.rt_product_details_select_type_ul li button:focus,
ul.rt_product_details_select_type_ul li button:active {
    border: 1px solid #d6d6d6;
    border-radius: 5px;
    background: white;
    font-weight: 600;
    font-size: 16px;
    color: #2F2C51;
    padding: 7px 15px;
    outline: none;
    box-shadow: none;
}

.rt_product_details_cart_btn_main button,
.rt_product_details_cart_btn_main button:hover,
.rt_product_details_cart_btn_main button:focus,
.rt_product_details_cart_btn_main button:active {
    width: 100%;
    margin-top: 30px;
    border-radius: 0;
    padding: 15px 20px;
    background: #00233C;
    border-color: #00233C;
    outline: none;
    box-shadow: none;
}

.rt_product_details_cart_btn_main button{
    color: white !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 22px;
    text-transform: uppercase;
}

.rt_product_details_cart_btn_main button svg {
    font-size: 32px;
}

.rt_product_details_replacement_content {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.rt_product_details_replacement_content p {
    margin: 0;
    font-weight: 700;
    font-size: 16px;
}

.rt_product_details_replacement_content p svg {
    color: #b1b1b1;
    font-size: 24px;
    margin-right: 2px;
    position: relative;
    top: -2px;
}

ul.rt_product_details_select_type_ul {
    padding: 0;
}

.rt_product_overview_title h2 {
    font-weight: 400;
    font-size: 28px;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.rt_product_overview_title h3 {
    font-weight: 400;
    font-size: 24px;
    text-transform: uppercase;
}

.rt_product_overview_title p {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
}

.rt_product_specifications {
    margin-top: 50px;
    width: 90%;
}

.rt_product_overview_title {
    margin-top: 50px;
}

.rt_product_specifications h3 {
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 24px;
    text-transform: uppercase;
}

.rt_product_specifications_list ul li h4 {
    font-weight: 500;
    font-size: 19px;
}

.rt_product_specifications_list ul li p {
    font-weight: 400;
    font-size: 20px;
    color: grey;
}

.rt_product_specifications_list {
    display: flex;
}

.rt_product_specifications_list ul li {
    border-bottom: 1px solid #e5e5e5;
    padding-top: 1rem;
}

.rt_product_specifications_list ul {
    width: 50%;
    margin-right: 60px !important;
}

.rt_product_review_main {
    margin-top: 50px;
}

.rt_product_review_btns_main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 50px;
}

.rt_product_review_btns_div {
    display: flex;
}

.rt_product_review_count_text span {
    font-weight: 400;
    font-size: 20px;
    color: #989898;
}

.rt_product_review_btn,
.rt_product_review_btn:hover,
.rt_product_review_btn:active,
.rt_product_review_btn:focus {
    border-color: #00233c;
    background: #00233C;
    border-radius: 5px;
    padding: 0px 20px;
    font-weight: 400;
    font-size: 18px;
    outline: none;
    box-shadow: none;
    margin-left: 15px;
    text-transform: uppercase;
}

.rt_product_details_shortby span {
    background: white;
    border-right: none;
    font-weight: 500;
    font-size: 16px;
    border-radius: 6px;
}

.rt_product_details_shortby select {
    border-left: none;
    font-size: 16px;
    font-weight: 500;
    border-radius: 6px;
    text-transform: uppercase;
}

.rt_product_details_shortby select:focus,
.rt_product_details_shortby select:focus:active {
    box-shadow: none !important;
    outline: none !important;
    border-color: none !important;
}

.rt_product_details_shortby .input-group {
    height: 53px;
}

.rt_product_review_main h3 {
    font-weight: 400;
    font-size: 24px;
    text-transform: uppercase;
}

.rt_product_review_rating_and_date {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rt_product_review_box {
    background: #FFFFFF;
    /* box-shadow: 0px 0px 35px rgb(210 210 210 / 54%); */
    border-radius: 12px;
    padding: 20px 25px;
    margin-top: 25px;
}

.rt_product_review_star span {
    font-size: 1.8rem;
}

.rt_product_review_text_content ul li {
    display: inline-block;
    margin-right: 10px;
}

.rt_product_review_text_content h4 {
    font-weight: 600;
    font-size: 20px;
}

.rt_product_review_text_content p {
    font-weight: 400;
    font-size: 17px;
}

.rt_product_review_user span {
    margin-left: 5px;
    font-weight: 600;
    font-size: 17px;
}

.rt_product_review_user {
    margin-top: 15px;
}

.rt_product_review_date span {
    margin-right: 15px;
}

button.review_load_btn,
button.review_load_btn:hover,
button.review_load_btn:focus,
button.review_load_btn:active {
    border: 2px solid #00233C;
    background: none;
    margin-top: 40px;
    padding: 10px 40px;
    border-radius: 0;
    outline: none;
    box-shadow: none;
    font-weight: 600;
    font-size: 18px;
    color: #00233C;
}

.rt_product_you_might_title p {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #8b8b8b;
}

.rt_product_you_might_title {
    text-align: center;
    margin-bottom: 50px;
}

.rt_product_you_might_title h2 {
    font-weight: 400;
    font-size: 40px;
}

.rt_product_review_box_main {
    max-height: 1050px;
    width: 100%;
    overflow: auto;
}

@media (min-width:1500px) and (max-width:1600px) {
    .rt_product_details_text_content_main img.slick-arrow.slick-next {
        right: -1px;
    }
    .rt_product_details_text_content_main img.slick-arrow.slick-prev {
        left: -1px;
        z-index: 99;
    }
    .rt_product_details_text_content_main {
        margin-left: 10px;
    }
    .rt_product_details_title h2 {
        font-size: 26px;
        line-height: 30px;
    }
    .rt_product_details_color {
        margin-top: 20px;
        padding-bottom: 15px;
    }
    .rt_product_details_select_type {
        margin-top: 20px;
    }
    .rt_product_details_replacement_content {
        margin-top: 10px;
    }
    .rt_product_details_cart_btn_main button, .rt_product_details_cart_btn_main button:hover, .rt_product_details_cart_btn_main button:focus, .rt_product_details_cart_btn_main button:active {
        margin-top: 22px;
    }
}


@media (min-width:1200px) and (max-width:1440px) {
    .rt_product_details_text_content_main {
        width: 100%;
        margin-left: 0px;
        position: sticky;
        top: 75px;
    }
    .rt_product_details_text_content_main img.slick-arrow.slick-prev {
        left: -20px;
    }
    .rt_product_specifications_list ul li h4 {
        font-weight: 500;
        font-size: 18px;
    }

    .rt_product_specifications_list ul li p {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .rt_product_specifications {
        margin-top: 30px;
        width: 100%;
    }

    .rt_product_review_main {
        margin-top: 30px;
    }

    .rt_product_overview_title h2 {
        font-size: 24px;
        margin-bottom: 15px;
    }

    .rt_product_overview_title h3 {
        font-size: 20px;
    }

    .rt_product_overview_title p {
        font-size: 14px;
        line-height: 20px;
    }

    .rt_product_details_price_text {
        margin-top: 0px;
    }

    .rt_product_details_size_slide_item_box {
        padding: 6px 8px;
    }

    .rt_product_details_size_slide_item_box {
        width: 90%;
        margin: 0 auto;
    }

    .rt_product_details_title h2 {
        font-size: 28px;
        line-height: 36px;
        padding-right: 30px;
    }

    .rt_product_details_size_slider h3 {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .rt_product_details_color h3 {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .rt_product_details_select_type h3 {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .rt_pd_review_btn {
        margin-top: 0px;
    }

    .rt_product_price_review_main {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        margin-top: 5px;
    }

    .rt_product_details_size_slide_item_box p.rt_pd_p-1 {
        font-size: 14x;
    }

    .rt_product_details_size_slide_item_box p.rt_pd_p-2 {
        font-size: 14px;
    }

    ul.rt_product_details_select_type_ul li button,
    ul.rt_product_details_select_type_ul li button:hover,
    ul.rt_product_details_select_type_ul li button:focus,
    ul.rt_product_details_select_type_ul li button:active {
        font-weight: 400;
        font-size: 14px;
        padding: 7px 15px;
    }

    .rt_product_details_cart_btn_main button,
    .rt_product_details_cart_btn_main button:hover,
    .rt_product_details_cart_btn_main button:focus,
    .rt_product_details_cart_btn_main button:active {
        margin-top: 20px;
        padding: 10px 20px;
    }

    .rt_product_details_replacement_content {
        margin-top: 12px;
    }

    .rt_product_details_size_slider {
        margin-top: 5px;
        padding-bottom: 20px;
    }

    .rt_product_details_color {
        margin-top: 15px;
        ;
        padding-bottom: 5px;
    }

    .rt_product_details_select_type {
        margin-top: 15px;
    }

    .rt_product_details_cart_btn_main button {
        font-weight: 600;
        font-size: 20px;
    }

    .rt_product_details_cart_btn_main button svg {
        font-size: 22px;
    }
    .rt_product_review_btn, .rt_product_review_btn:hover, .rt_product_review_btn:active, .rt_product_review_btn:focus {
        font-size: 16px;
        letter-spacing: 1.2px;
        text-transform: uppercase;
    }
    .rt_product_review_count_text span {
        font-size: 18px;
    }
    .rt_product_review_box {
        margin-top: 0;
        margin-bottom: 15px;
    }
    .rt_product_review_text_content h4 {
        font-size: 16px;
    }
    .rt_product_review_text_content p {
        font-weight: 400;
        font-size: 14px;
    }
    .rt_product_review_user span {
        font-size: 14px;
    }
    .rt_product_details_slider_thumb .image-gallery-thumbnail {
        display: block;
        width: 98%;
        height: 85px;
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .rt_product_details_text_content_main {
        margin-left: 0px;
    }
    .rt_product_details_replacement_content p {
        margin: 0;
        font-weight: 700;
        font-size: 14px;
    }
    ul.rt_product_details_select_type_ul li button, ul.rt_product_details_select_type_ul li button:hover, ul.rt_product_details_select_type_ul li button:focus, ul.rt_product_details_select_type_ul li button:active {
        font-size: 14px;
    }
    .rt_product_details_title h2 {
        font-size: 28px;
        line-height: 41px;
        padding-right: 20px;
    }

    .rt_product_details_text_content_main img.slick-arrow.slick-next {
        right: -5px;
    }

    .rt_product_details_text_content_main img.slick-arrow.slick-prev {
        left: -8px;
        z-index: 9;
    }

    .rt_product_details_size_slide_item_box {
        background: #ffffff;
        border: 1px solid #d6d6d6;
        border-radius: 7px;
        width: 90%;
        text-align: center;
        padding: 10px 8px;

    }

    .rt_product_details_slider_thumb .image-gallery-thumbnail {
        height: 80px;
    }

    .rt_product_review_btns_main {
        display: block;
        margin-top: 15px;
        margin-bottom: 10px;
    }

    .rt_product_review_btns_div {
        width: 100%;
        margin-top: 11px;
    }

    .rt_product_review_main {
        margin-top: 30px;

    }

    .rt_product_review_box {
        padding: 20px 20px;
        margin-top: 5px;
        margin-bottom: 20px;
    }

    section.rt_product_details_product_section .rt_product_list.rt_product_wishlist {
        padding: 2rem 0;
    }
}

@media (min-width:768px) and (max-width:992px) {
 
    .rt_product_details_title {
        padding-right: 0px;
        margin-top: 20px;
    }
    .rt_product_details_slider_thumb .image-gallery-thumbnail {
        height: 80px;
        width: 100%;
    }

    .rt_product_details_text_content_main {
        width: 100%;
        margin-left: 0px;
        position: inherit;
        padding: 0px 10px;
    }

    .rt_product_details_price_text {
        margin-top: 0px;
    }

    .rt_product_price_review_main {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .rt_product_details_size_slide_item_box {
        width: 80%;
        padding: 10px 8px;
        margin: auto;
    }

    .rt_product_details_text_content_main img.slick-arrow.slick-prev {
        left: -15px;
    }

    .rt_product_details_text_content_main img.slick-arrow.slick-next {
        right: -15px;
    }

    .rt_product_overview_title {
        margin-top: 30px;
    }

    .rt_product_specifications {
        margin-top: 30px;
        width: 100%;
    }

    .rt_product_review_btns_main {
        margin-bottom: 30px;
    }

    .rt_product_review_btn,
    .rt_product_review_btn:hover,
    .rt_product_review_btn:active,
    .rt_product_review_btn:focus {
        margin-left: 15px;
    }

    .rt_product_details_shortby select {
        font-size: 15px;
    }

    .rt_product_details_shortby span {
        font-size: 15px;
    }

    .rt_product_review_count_text span {
        font-size: 18px;
    }
}

@media (min-width:320px) and (max-width:767px) {   
    .rt_product_details_slider_thumb nav.image-gallery-thumbnails-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 10px;
    }
    section.rt_product_details_product_section .rt_product_list.rt_product_wishlist {
        padding: 0rem 0;
    }
    .rt_product_details_slider_thumb .image-gallery-thumbnail:nth-last-child(1) {
        margin-right: 0;
    }

    .rt_product_details_slider_thumb .image-gallery-thumbnail {
        height: 80px;
        border: 1px solid #dedede;
        overflow: hidden;
        margin-right: 5px;
    }

    .rt_product_details_slider_thumb .image-gallery-thumbnail.active,
    .image-gallery-thumbnail:focus {
        border: 1px solid #00233c;
    }

    .rt_breadcrumb li.MuiBreadcrumbs-li p {
        font-size: 14px;
    }

    .rt_breadcrumb li.MuiBreadcrumbs-li a svg {
        font-size: 18px;
    }

    .rt_breadcrumb li.MuiBreadcrumbs-li a {
        color: black;
        font-size: 14px;
    }

    .rt_breadcrumb nav {
        padding: 5px 0px 0px 0px;
    }

    .rt_product_overview_title p {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
    }

    .rt_order_1 {
        order: 1;
    }

    .rt_order_2 {
        order: 2;
    }

    .rt_order_3 {
        order: 3;
    }

    .rt_product_details_text_content_main {
        width: 100%;
        margin-left: 0px;
        position: inherit;
    }

    .rt_product_details_title {
        margin-top: 15px;
    }

    .rt_product_details_title h2 {
        font-size: 22px;
        line-height: 32px;
        padding-right: 10px;
    }

    .rt_product_details_size_slide_item_box {
        width: 90%;
        padding: 10px 8px;
        margin: auto;
    }

    .rt_product_details_text_content_main img.slick-arrow.slick-prev {
        left: -3px;
        z-index: 9;
    }

    .rt_product_details_text_content_main img.slick-arrow.slick-next {
        right: -4px;
    }

    ul.rt_product_details_select_type_ul li button {
        font-size: 14px;
    }

    ul.rt_product_details_select_type_ul li {
        margin-right: 5px;
        margin-bottom: 5px;
    }

    .rt_product_details_cart_btn_main button svg {
        font-size: 24px;
        color:  #fff !important;
    }

    .rt_product_details_cart_btn_main button {
        font-size: 16px;
        color:  #fff !important;
    }

    .rt_product_details_replacement_content p svg {
        font-size: 18px;
        margin-right: 2px;
    }

    .rt_product_details_replacement_content p {
        font-size: 11px;
    }

    .rt_product_overview_title {
        margin-top: 30px;
    }

    .rt_product_overview_title h3 {
        font-size: 20px;
    }

    .rt_product_overview_title h2 {
        font-size: 24px;
        margin-bottom: 16px;
    }

    .rt_product_specifications {
        margin-top: 30px;
        width: 100%;
    }

    .rt_product_specifications_list ul li p {
        font-size: 12px;
        margin-bottom: 5px;
    }

    .rt_product_specifications_list ul li h4 {
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 5px;
    }

    .rt_product_specifications_list ul {
        width: 50%;
        margin-right: 15px !important;
    }

    .rt_product_review_count_text span {
        font-size: 16px;
    }

    .rt_product_review_count_text {
        margin-bottom: 12px;
    }

    .rt_product_details_shortby {
        margin-bottom: 12px;
    }

    .rt_product_review_btn,
    .rt_product_review_btn:hover,
    .rt_product_review_btn:active,
    .rt_product_review_btn:focus {
        padding: 13px 20px;
        font-weight: 400;
        font-size: 16px;
        width: 100%;
        margin-left: 0;
    }

    .rt_product_review_btns_main {
        display: block;
        margin-top: 15px;
        margin-bottom: 30px;
    }

    .rt_product_review_btns_div {
        display: block;
    }

    .rt_product_review_main {
        margin-top: 30px;
    }

    .rt_product_review_box {
        padding: 15px 15px;
        margin-top: 0px;
        margin-bottom: 15px;
    }

    .rt_product_review_star span {
        font-size: 1.2rem;
    }

    .rt_product_review_date span {
        margin-right: 15px;
        font-size: 12px;
    }

    .rt_product_review_text_content p {
        font-weight: 400;
        font-size: 14px;
    }

    .rt_product_review_text_content h4 {
        font-size: 18px;
    }

    .rt_product_review_text_content ul li {
        margin-bottom: 10px;
        width: 30%;
        margin-right: 5px;
    }

    .rt_product_review_text_content_img img {
        width: 100%;
    }

    .rt_product_review_user {
        margin-top: 5px;
    }

    .rt_product_details_cart_btn_main button {
        margin-top: 20px;
    }

    .rt_product_specifications_list ul li {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

}
.rt-sku{
    color: #000000;
    font-size: 15px;
    font-weight: 800;
}