/*My Account Css*/
.rt_myaccount {
    padding: 0 0 50px 0;
}

.MuiTabs-indicator {
    background-color: transparent !important;
}

.rt_myaccount .MuiTabs-root {
    border: 1px solid rgb(0 0 0 / 2%) !important;
    /* width: 350px !important; */
    height: 397px !important;
    border-radius: 6px;
}

.rt_logout_icn {
    font-size: 22px;
    transform: rotate(90deg);
}

.makeStyles-root-4 {
    display: unset !important;
}

.MuiTab-textColorInherit {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.MuiTab-labelIcon {
    min-height: 60px !important;
    padding-top: 9px;
    text-align: left !important;
}

.rt_side_tabs span svg {
    margin-right: 15px;
}

.MuiTab-textColorInherit {
    max-width: 100% !important;
}

.MuiTab-textColorInherit.Mui-selected {
    opacity: 1;
    background-color: #00233C;
    color: #fff;
    max-width: 100%;
}

.rt_side_tabs .MuiTab-wrapper {
    display: block !important;
}

.rt_myaccount-content {
    padding: 30px;
    background-color: #f5f8fe;
}

.rt_side_tabpanel .MuiBox-root {
    margin: 0 24px !important;
    background-color: #fff;
    border-radius: 5px;
    padding: 20px !important;
}

.rt_myaccount-content h5 {
    border-bottom: 1px dashed #ccc;
    padding-bottom: 10px;
    margin-bottom: 26px;
    font-size: 20px;
    padding-bottom: 22px;
}

.rt_myaccount_input {
    padding: 40px 0 0;
}

.rt_acount_mg {
    margin-top: 138px;
}

.rt_add_new {
    padding: 10px 30px !important;
    box-shadow: 0 5px 19px rgb(34 58 109 / 42%) !important;
}

.MuiTabs-flexContainerVertical {
    background-color: #fff !important;
}

.makeStyles-root-4 {
    background-color: transparent !important;
}

.rt_order_table {
    padding: 30px 0 10px;
}

.rt_fill_button {
    color: #fff !important;
}

.rt_fill_button svg {
    color: #fff !important;
}

.rt_add_box {
    background: #fff;
    padding: 15px 15px 5px;
    margin-bottom: 22px;
    height: 300px;
    box-shadow: 0 5px 10px 5px #eee;
    position: relative;
}

.rt_icn451 {
    width: 100%;
    float: left;
    text-align: right;
    margin-top: 5px;
}

.rt_add_box address {
    margin-bottom: 20px;
    font-style: normal;
    line-height: 1.42857143;
}

.rt_add_box address h4 {
    font-weight: bold;
    font-size: 14px;
}

.rt_add_box .mob_add {
    margin-bottom: 3px;
    color: #676767;
}

.rt_add_box .myacc_edit {
    /* float: right; */
    font-size: 16px;
    /* margin-top: -5px; */
    cursor: pointer;
    /* position: absolute;
    bottom: 22px;
    right: 60px; */
}

span.rt_add_box_address_text {
    height: 41px;
}

.rt_add_box .myacc_remove {
    /* float: right; */
    font-size: 16px;
    /* margin-top: -5px; */
    cursor: pointer;
    /* position: absolute; */
    /* bottom: 22px; */
    /* right: 20px; */
}

.rt_add_box p {
    width: 100%;
    margin-bottom: 5px;
    display: flex;
}

.rt_add_box p span {
    margin-bottom: 10px !important;
}

.rt_address_btn {
    background-color: rgb(236 236 236) !important;
    color: #000 !important;
    font-weight: 500 !important;
    text-transform: capitalize !important;
    letter-spacing: 0.6px !important;
    font-size: 12px !important;
    border-radius: 5px !important;
    margin: 15px 5px 0 !important;
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}

.rt_myaccount_desktop .rt_myacc_wish button {
    width: 100%;
}

.rt_myaccount_desktop .rt_myacc_wish button span {
    color: #212529 !important;
}

.rt_myaccount_mobile .rt_myacc_wish button {
    width: 100%;
    background-color: #fdfdfd;
    color: #00233C;
    opacity: 1;
    border: 1px solid #ccc;
    border-right: 0;
    border-left: 0;
    padding: 0 14px;
    min-height: 30px !important;
    height: 50px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.rt_myaccount_mobile .rt_myacc_wish span {
    display: block;
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    text-transform: capitalize;
}

.rt_myaccount_mobile .rt_myacc_wish span svg {
    margin-right: 7px;
}

.rt_myaccount_mobile .MuiAccordionDetails-root {
    display: block;
}

.rt_myaccount_mobile .MuiTypography-body1 {
    color: #00233C !important;
}

.rt_order_table table {
    margin: 0;
}

/*New Css*/
.rt_myaccount {
    padding: 5rem 0;
}

.rt_myaccount_content_left h2 {
    font-size: 30px;
    line-height: 27px;
    font-family: 'Limerick' !important;
    text-transform: capitalize;
    margin-bottom: 35px;
}

.rt_myaccount_content_left label {
    font-weight: 600;
    font-size: 14px;
    font-family: 'Nunito', sans-serif !important;
}

.rt_myaccount_content_left input {
    font-weight: 600;
    font-size: 14px;
    font-family: 'Nunito', sans-serif !important;
}

.rt_myaccount_content_left textarea,
.rt_myaccount_content_left textarea:focus,
.rt_myaccount_content_left textarea:active {
    background: #f0f0f0;
    padding: 10px;
    border-color: #ededed;
    outline: none;
    box-shadow: none;
    font-family: 'Nunito', sans-serif !important;
}

.rt_myaccount_content_left {
    box-shadow: none;
    border-radius: 0;
}

.rt_myaccount_content_left button,
.rt_myaccount_content_left button:hover,
.rt_myaccount_content_left button:active,
.rt_myaccount_content_left button:focus {
    width: auto;
    margin: 20px auto 10px !important;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
    padding: 15px 70px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    border-radius: 10px !important;
    color: #fff !important;
    display: flex;
}

.rt_myaccount_content_left label {
    font-weight: 600;
    font-size: 14px;
}

.rt_myaccount_content_left input,
.rt_myaccount_content_left input:focus,
.rt_myaccount_content_left input:active {
    padding: 10px;
    border-color: #ededed;
    outline: none;
    box-shadow: none;
    background: #f0f0f0;
    border-radius: 7px !important;
    height: 60px;
}

.rt_myaccount_content_left select,
.rt_myaccount_content_left select:focus,
.rt_myaccount_content_left select:active {
    background: #f0f0f0;
    padding: 10px;
    border-color: #ededed;
    outline: none;
    box-shadow: none;
    margin-bottom: 15px;
    font-family: 'Nunito', sans-serif !important;
}

.rt_myaccount_content_left .form-check {
    margin: 20px 0;
    display: flex;
    justify-content: start;
    align-items: center;
}

.rt_fill_button {
    background-color: #00233C !important;
    border-color: #00233C !important;
    outline: none !important;
    box-shadow: none !important;
    border-radius: 8px !important;
}

.rt_myaccount_content_left input.form-check-input,
.rt_myaccount_content_left input.form-check-input:focus,
.rt_myaccount_content_left input.form-check-input:active {
    background-color: #f0f0f0;
    padding: 10px;
    border-color: #ededed;
    outline: none;
    height: auto;
    box-shadow: none;
    margin-right: 5px;
    font-family: 'Nunito', sans-serif !important;
}

.rt_myaccount_content_left .form-check-input:checked {
    background-color: #00233C !important;
    border-color: #00233C !important;
}

.rt_myaccount_content {
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    position: relative;
    margin: 30px 0 20px;
    box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
}

.rt_myaccount .MuiTabs-flexContainer.MuiTabs-flexContainerVertical {
    padding: 30px;
}

.rt_myaccount .rt_side_tabs.Mui-selected {
    border-radius: 10px !important;
    position: relative;
}

.rt_add_box.rt_checkout_box {
    height: 240px;
}

.rt_checkout_checkbox {
    position: absolute;
    right: 10px;
    top: 5px;
}
p.rt_checkout_name_text {
    width: 78%;
    height: 37px
}
.rt_icn451.rt_icn_checkout {
    position: absolute;
    right: 0;
    top: 0;
}
.rt_checkbox_5243 .form-check {
    margin: 8px 0px;
}
.rt_checkbox_5243 .form-check label.form-check-label {
    font-size: 12px;
}
.rt_checkbox_5243 .form-check {
    margin: 6px 0px;
}
.rt_add_box.rt_checkout_box {
    height: 225px;
}
.rt_add_box.rt_checkout_box {
    position: relative;
}
.rt_checkout_checkbox .form-check {
    margin: 0;
}
/*New Css*/
/*My Account Css*/
@media (min-width: 1024x) and (max-width: 1199px) {
    .rt_add_box {
        height: 280px !important;
    }
}

@media (min-width: 1200px) and (max-width: 1450px) {
    .rt_add_box p span {
        margin-bottom: 5px !important;
    }

    span.rt_add_box_address_text {
        height: 41px;
        font-size: 14px;
        line-height: 17px;
    }

    .rt_add_box {
        height: 280px;
    }

}



@media (min-width: 320px) and (max-width: 767px) {
    .rt_checkout_sec {
        padding: 10px;
        margin-bottom: 30px;
    }

    .rt_top_select {
        margin: 0 !important;
    }

    .rt_add_new {
        padding: 10px 20px !important;
    }

    .rt_add_box p span {
        font-size: 14px;
    }

    .rt_add_box {
        padding: 15px 15px 5px;
    }

    .rt_cart_summary1 {
        margin-top: 20px;
    }

    .rt_top_select {
        margin: 0 2px 0 0 !important;
    }

    .rt_myaccount_mobile {
        padding: 2rem 0;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {

    .rt_myaccount_mobile .MuiPaper-elevation1,
    .rt_myaccount_mobile .rt_myacc_wish button {
        box-shadow: none !important;
    }

    .rt_add_box p {
        font-size: 14px;
    }

    .rt_cart_summary1 {
        margin-top: 20px;
    }

    .rt_top_select {
        margin: 0 2px 0 0 !important;
    }
}

@media (min-width: 1150px) and (max-width: 1440px) {
    .rt_myaccount .MuiTabs-flexContainer.MuiTabs-flexContainerVertical {
        padding: 20px;
    }
}